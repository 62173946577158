body, html {
  font-family: "Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"
}
a {color: #1a0dab }
.blue a {color: #379ED9 !important;}
img { max-width: 100%; height: auto; }

iframe { max-width: 100%; }

strong { font-weight: bold !important; }
.strong { font-weight: bold !important; }
p { margin-bottom: 30px; }

i {font-style: normal !important;}

.receiver-field {
  opacity: 1;
  pointer-events: none;
  color: rgba(111, 109, 109, 0.8) !important;
}

.validation-advice, .notice-msg, #error-message, .error-msg, .success-msg, .error-message {    border:1px solid #911;
  background-color:#fff6f6;
  padding:5px 10px;
  border-radius:5px;
  clear:both;
  float:none;
  color:#911 !important;
  margin:10px 0;
  font-weight:400;
}
.warning-advice {
  border: 1px solid #8cae2e;
  background-color: #edf9cb;
  padding: 5px 10px;
  border-radius: 5px;
  clear: both;
  float: none;
  color: #3c3c3b !important;
  margin: 10px 0;
  font-weight: 400;
}

.iban-warning {
  border:1px solid #fcd344;
  background-color:#fafaec;
  padding:5px 10px;
  border-radius:5px;
  clear:both;
  float:none;
  color:#911 !important;
  margin:10px 0;
  font-weight:400;
}

.transfer-summary #error-message {
  margin:10px;
}

.gender,
.newsletter {
  color: #0a0a0a !important;
}

.newsletter-page{padding-top: 50px;text-align: center;padding-bottom: 50px;}

.success-msg {
  border:1px solid #7AAA65;
  background-color:#ffffff;
  color:#7AAA65 !important;
}

input[type="text"], input[type="password"], input[type="email"], input[type="date"], input[type="number"], textarea, .select-wrapper input.select-dropdown { background: white; border: none; height: 55px; }

input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, input[type="number"]:focus, .select-wrapper input.select-dropdown:focus, input[type="text"].valid, input[type="password"].valid, input[type="email"].valid, textarea:focus, input[type="text"]:focus:not([readonly]), input[type="password"]:focus:not([readonly]), input[type="email"]:focus:not([readonly]) { border: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

[type="radio"].with-gap:checked+label:before,
[type="radio"].with-gap:checked+label:after{border:2px solid #a4c843}
[type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:after{background-color:#a4c843}
[type="radio"]:not(:checked)+label:before{background:#f9f9f9;background:-moz-linear-gradient(top, #f9f9f9 0%, #ccc 100%);background:-webkit-gradient(linear, left top, left bottom, color-stop(0%, #f9f9f9), color-stop(100%, #ccc));background:-webkit-linear-gradient(top, #f9f9f9 0%, #ccc 100%);background:-o-linear-gradient(top, #f9f9f9 0%, #ccc 100%);background:-ms-linear-gradient(top, #f9f9f9 0%, #ccc 100%);background:linear-gradient(to bottom, #f9f9f9 0%, #ccc 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#cccccc',GradientType=0 );-webkit-box-shadow:inset 0 0 4px 0 rgba(51,51,51,0.34);-moz-box-shadow:inset 0 0 4px 0 rgba(51,51,51,0.34);box-shadow:inset 0 0 4px 0 rgba(51,51,51,0.34);border:none}

.select-wrapper input.select-dropdown { -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; padding: 0 4% 0 8%; width: 88%; font-weight: 600; text-transform: uppercase; background: #fff url("./../img/icons/arrow-down-green.png") no-repeat center right 10px; }

.select-wrapper .mdi-navigation-arrow-drop-down { display: none; }

.dropdown-content li > a, .dropdown-content li > span { color: #a4c843; }

[type="checkbox"].filled-in:checked+label:after{border:2px solid #a4c843;background-color:#a4c843}

.top-wrapper { position: relative; }

.topup-number-check #warning-message { display: none !important; }

/* Top header */
.top-header { background: #bee1fe; height: 70px; }
.top-header .logo { margin-top: 14.5px; }

.top-menu { margin: 0; height: 70px; display: table; }
.top-menu li { display: table-cell; vertical-align: middle; padding: 0 10px; position: relative; }
.top-menu li .language-switcher li span { width: 20px; float: left; display: inline-block; height: 12px; position: relative; top: 6px; margin-right: 5px; }
.top-menu li .sub-menu { opacity: 0; visibility: hidden; background: white; z-index: 1; top: 60px; right: 0px; bottom: auto; left: auto; position: absolute; min-width: 77px; padding: 0 15px; border-bottom: 5px solid #009eda; -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; transform: translateY(-20px); -webkit-transform: translateY(-20px); }
.top-menu li .sub-menu.active { opacity: 1; visibility: visible; transform: translateY(0px); -webkit-transform: translateY(0px); }
.top-menu li .sub-menu.login-container { width: 300px; padding-bottom: 15px; }
.top-menu li .sub-menu.login-container input { background: #eee; padding: 0 5%; width: 90%; margin-bottom: 0; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.top-menu li .sub-menu.login-container a { margin: 10px 10px 0; display: inline-block; }
.top-menu li .sub-menu.login-container .social-button { margin-top: 80px; }
.top-menu li .sub-menu.login-container button.btn { background: #009eda 0; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; width: 150px; height: 45px; line-height: 45px; margin: 0; font-size: 12px; font-weight: 700; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.top-menu li .sub-menu.login-container button.btn:hover { background: #0080b1 0; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.top-menu li .sub-menu.login-container button.btn:active { background: #00678d 0; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.top-menu li .sub-menu.login-container #sociallogin_button ul li.button, .top-menu li .sub-menu.login-container .sociallogin-other ul li { width: 100%; padding: 0 15px; }
.top-menu li .sub-menu.login-container button.bt-login-social, .top-menu li .sub-menu.login-container #sociallogin_button button > span { width: 100%; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; height: 45px !important; line-height: 45px; }
.top-menu li .sub-menu.login-container button.bt-login-social, .top-menu li .sub-menu.login-container #sociallogin_button button > span > span { width: 94%; height: 45px !important; line-height: 45px; margin-left: 30px; font-size: 14px; color: white; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.top-menu li .sub-menu.login-container #sociallogin_button #bt-loginfb > span, .top-menu li .sub-menu.login-container #bt-loginfb-popup > span { background: url("../img/fb.png") no-repeat !important; }
.top-menu li .sub-menu.login-container #sociallogin_button #bt-logingo > span, .top-menu li .sub-menu.login-container #bt-logingo-popup > span { background: url("../img/go.png") no-repeat !important; }
.top-menu li .sub-menu.login-container #sociallogin_button #bt-loginya > span, .top-menu li .sub-menu.login-container #bt-loginya-popup > span { background: url("../img/ya.png") no-repeat !important; }
.top-menu li .sub-menu li { display: block; padding: 5px 0; }
.top-menu li .sub-menu li a { font-size: 14px; color: black; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; font-weight: 600; }
.top-menu li .sub-menu li a:hover { color: #009eda; }
    .top-menu li a, .top-menu li span {
        color: #1a0dab !important;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        -ms-transition: 0.3s;
        transition: 0.3s;
    }
.top-menu li a:hover, .top-menu li span:hover { color: #009eda; }
.top-menu li .login { background: #009eda; color: white; padding: 8px 25px; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; }
.top-menu li .login:hover { color: white; cursor: pointer; }
.top-menu li .signup { background: white; color: #009eda; padding: 8px 25px; -webkit-box-shadow: inset 0 0 0 1px #cfcfcf; -moz-box-shadow: inset 0 0 0 1px #cfcfcf; box-shadow: inset 0 0 0 1px #cfcfcf; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; }
.top-menu li .signup:hover { background: #009eda; color: white; }
.top-menu li .signup.active { background: #a4c843; color: white; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.top-menu li .signup.active:hover { background: #87a731; }
.top-menu li.has-sub a span { float: left; position: relative; top: 12px; margin-right: 5px; }
.top-menu li.has-sub a img { position: relative; top: 4px; }
.top-menu li.has-sub:hover { cursor: pointer; }
.top-menu .go-log-img {width:35px;height:35px;margin-top:15px;}

/* Header */
.header { height: 60px; }

.header.fixed { position: fixed; width: 100%; top: 0; left: 0; background: white; z-index: 2; -webkit-box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.17); -moz-box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.17); box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.17); }
.header.fixed .logo { margin-top: 10px; -webkit-transition: 0.4s; -moz-transition: 0.4s; -o-transition: 0.4s; -ms-transition: 0.4s; transition: 0.4s; transform: translateY(0px); -webkit-transform: translateY(0px); }

.hidden-logo { opacity: 0; visibility: hidden; transform: translateY(-20px); -webkit-transform: translateY(-20px); display: none; }

.scroll-item-to-hidden.hidden{ display: none; }

.main-menu { margin: 0; height: 60px; display: table; }
.main-menu li { display: table-cell; vertical-align: middle; padding: 0 20px; position: relative; }
.main-menu li:hover .sub-menu { opacity: 1; visibility: visible; transform: translateY(0px); -webkit-transform: translateY(0px); }
.main-menu li .sub-menu { opacity: 0; visibility: hidden; top: 60px; right: auto; bottom: auto; left: 0; position: absolute; background: white; z-index: 1; padding: 0 15px; min-width: 160px; border-bottom: 5px solid #009eda; -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; transform: translateY(-20px); -webkit-transform: translateY(-20px); }
.main-menu li .sub-menu li { display: block; padding: 15px 0; }
.main-menu li .sub-menu li a { font-size: 14px; color: black; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; font-weight: 600; }
.main-menu li .sub-menu li a:hover { color: #009eda; }
.main-menu li a { color: #009eda; font-weight: 700; text-transform: uppercase; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }
.main-menu li a:hover { color: black; }
/* Intro */
.intro { padding: 50px 0; }
@media screen and (max-width: 640px) {
  .checkout{ padding: 0px !important; }
  .checkout .step-list { margin-top: 5px; }
  .checkout .step-list:first-child .row { margin-bottom: 5px !important; }
  .checkout .container { padding: 0px !important; }
  .checkout .transfer-summary { border-radius: 0px !important; }
}
.intro.home { background: url("../img/intro.jpg") no-repeat center center !important; -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='../img/intro.jpg', sizingMethod='scale')" !important; -webkit-background-size: cover !important; background-size: cover !important; }
.intro.no-pad { padding: 20px 0; }
.intro.gray { background: #eee !important; }
.intro .landing-text h1 { color: #009eda; font-size: 36px; margin-top: 20px; margin-bottom: 60px; line-height: 40px; }


.intro .landing-text h1 span { color: black; font-weight: 700; margin-top: 10px; }
.intro .landing-text p { font-size: 23px; margin-top: 0; margin-bottom: 60px; font-weight: 300; }
.intro .landing-text .check input[type="text"] { padding: 0 5%; width: 50%; margin-right: 40px; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; float: left; font-size: 18px; }
.intro .landing-text .check input[type="submit"] { padding: 0 1em 0 1em !important; background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; float: left; height: 55px; max-width: 146px; text-transform: uppercase; border: none; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.intro .landing-text .check input[type="submit"]:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.intro .landing-text .check input[type="submit"]:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.intro .landing-text .marketing { padding-top: 40px; position: relative; display: table; width: 100%; }
.intro .landing-text .marketing input[type="text"] { padding: 0 1.18em; width: 60%; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; float: left; font-size: 18px; }
.intro .landing-text .marketing input[type="submit"] { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; float: left; height: 55px; text-transform: uppercase; border: none; max-width: 139px; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; padding: 0 1.5rem;}
.intro .landing-text .marketing input[type="submit"]:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.intro .landing-text .marketing input[type="submit"]:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.intro .landing-text .marketing .validation-advice { position: absolute; bottom: -2.5em; width: 31em; }
@media screen and (max-width: 640px) {
  .intro .landing-text .marketing input[type="submit"] { text-align: center; }
  .intro .landing-text .marketing .validation-advice { position: absolute; bottom: -2.5em; width: 31em; }

}
.intro .landing-text .online-banking { padding-top: 30px; position: relative; }
.intro .landing-text .online-banking .warning-container { top: 100px; right: auto; bottom: auto; left: auto; position: absolute; }
.intro .landing-text .online-banking .warning-container .warning { -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); color: #d90000; padding: 5%; overflow: initial; }
.intro .landing-text .online-banking .warning-container .warning:before { content: ""; top: -10px; right: auto; bottom: auto; left: 50px; position: absolute; width: 0; height: 0; border-style: solid; border-width: 0 10px 10px 10px; border-color: transparent transparent #ffffff transparent; }
.intro .landing-text .online-banking .has-icon { position: relative; }
.intro .landing-text .online-banking .has-icon img { position: absolute; right: 20px; top: 10px; }
.intro .landing-text .online-banking input[type="text"] { padding: 0 5%; width: 90%; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; float: left; font-size: 18px; }
.intro .landing-text .online-banking input[type="submit"] { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; float: left; height: 55px; text-transform: uppercase; border: none; width: 120px; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.intro .landing-text .online-banking input[type="submit"]:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.intro .landing-text .online-banking input[type="submit"]:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.intro h1 { font-size: 60px; line-height: 60px; font-weight: 800; color: white; margin: 50px auto 0; }
.intro h3 { font-size: 34px; margin-top: 0; }
.intro p.promotions-text { font-size: 24px; font-weight: 600; text-align: center; margin: 0 auto 10px; }
.intro p.promotions-text a { font-size: 16px; }
.intro img.promotions-flag { margin-bottom: 20px; }


.intro .landing-check { margin: 0 !important; }
.intro .landing-check input { margin-bottom: 0; }


.intro .landing-check {
  padding-left: 0 !important;

}


.intro .selection { width: 660px; margin: auto; }
.intro .selection .countries-field { position: relative; }
.intro .selection .countries-field button { background: none; border: none; position: absolute; right: 5px; top: 5px; -webkit-border-radius: 42px; -moz-border-radius: 42px; border-radius: 42px; }
.intro .selection .countries-field .select-wrapper { display: none; }
@media screen and (min-width: 320px) and (max-width: 480px) { .intro .selection .countries-field .select-wrapper { display: block; }
  .intro .selection .countries-field .select-wrapper input.select-dropdown { background: #fff url("../img//icons//arrow-down-green.png") no-repeat center right 10px; -webkit-user-select: none; /* Chrome all / Safari all */ -moz-user-select: none; /* Firefox all */ -ms-user-select: none; /* IE 10+ */ user-select: none; /* Likely future */ }
  /*.intro .selection .countries-field .twitter-typeahead { display: none !important; }
  .intro .selection .countries-field button { display: none; }*/ }
.intro .selection .tt-menu.active { display: block; }
.intro .selection .twitter-typeahead, .intro .selection .tt-menu { width: 100%; }
.intro .selection input[type="text"] { width: 86%; float: left; background: white; height: 54px; padding: 0 8%; border: none; -webkit-border-radius: 40px; -moz-border-radius: 40px; border-radius: 40px; font-size: 18px; }
.intro .selection input[type="text"]:focus:not([readonly]) { border: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.intro .selection input[type="submit"] { width: 100%; float: left; -webkit-border-radius: 40px; -moz-border-radius: 40px; border-radius: 40px; background: #a4c843 url("../img/icons/button-transfer.png") no-repeat center left 8px; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; font-weight: 600; padding-left: 20%; font-size: 18px; }
.intro .selection input[type="submit"]:hover { background: #8daf33 url("../img/icons/button-transfer.png") no-repeat center left 8px; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.intro .selection input[type="submit"]:active { background: #77932b url("../img/icons/button-transfer.png") no-repeat center left 8px; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

.tt-dataset { padding: 15px 0; width: 100%; background: #FFF; position: relative; }
.tt-dataset:before { content: ""; top: -10px; right: auto; bottom: auto; left: 20px; position: absolute; width: 0; height: 0; border-style: solid; border-width: 0 10px 10px 10px; border-color: transparent transparent #ffffff transparent; }

.tt-suggestion { white-space: nowrap; overflow: hidden; padding: 5px 20px; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }
.tt-suggestion:hover { cursor: pointer; background: #a4c843; color: white; }
.tt-suggestion p { float: left; margin: 0; font-size: 14px; line-height: 32px; }

.autocomplete-selected { background: #F0F0F0; }

.autocomplete-suggestions strong { font-weight: 700; }

.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

.tt-menu { max-height: 200px; overflow-y: auto; border-bottom: 5px solid #009eda; position: relative; -webkit-box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.17); -moz-box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.17); box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.17); }

.reviews { -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; margin-top: 40px !important; padding: 20px; }

/* Testimonials */
.testimonials { padding: 50px 0; }
.testimonials h2 { margin-top: 0; margin-bottom: 0px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.testimonials p { margin-top: 0px; margin-bottom: 0px; font-size: 18px; }

/* Country slider */
.country-slider { margin: 80px auto; height: 180px; }
.country-slider li { min-height: 1px; position: relative; text-align: center; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }
.country-slider li.prev, .country-slider li.next { margin-top: 30px; height: 180px; -moz-transform: scale(1.4); -ms-transform: scale(1.4); -o-transform: scale(1.4); -webkit-transform: scale(1.4); transform: scale(1.4); position: relative; z-index: 999; }
.country-slider li.before-prev, .country-slider li.before-next { margin-top: 25px; height: 160px; }
.country-slider li.slick-center { -moz-transform: scale(1.7); -ms-transform: scale(1.7); -o-transform: scale(1.7); -webkit-transform: scale(1.7); transform: scale(1.7); color: #e67e22; opacity: 1; height: 180px; margin-top: 40px; position: relative; z-index: 9999; }
.country-slider .slick-next { background: url("../img/icons/slider-right-arrow-blue.png") no-repeat center center; width: 30px; height: 52px; }
.country-slider .slick-next:hover { background: url("../img/icons/slider-right-arrow-white.png") no-repeat center center; width: 30px; height: 52px; }
.country-slider .slick-prev { background: url("../img/icons/slider-left-arrow-blue.png") no-repeat center center; width: 30px; height: 52px; }
.country-slider .slick-prev:hover { background: url("../img/icons/slider-left-arrow-white.png") no-repeat center center; width: 30px; height: 52px; }
.country-slider .slick-prev:before, .country-slider .slick-next:before { display: none; }

.slick-dots { margin-bottom: 10px; display: none !important;  }
.slick-dots li { display: inline-block; width: initial; }

/* How it works */
.how-it-works { padding: 100px 0; background: url("../img/how-it-works.jpg") no-repeat center center; -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader( src='../img/how-it-works.jpg', sizingMethod='scale')"; -webkit-background-size: cover !important; background-size: cover !important; }
.how-it-works .row { margin-bottom: 0; }
.how-it-works h2 { margin-top: 0; margin-bottom: 95px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.how-it-works p { font-size: 24px; font-weight: 700; margin-top: 30px; margin-bottom: 0; }
.how-it-works a { margin-top: 135px; height: 55px; font-weight: 600; font-size: 18px; background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 40px; -moz-border-radius: 40px; border-radius: 40px; }
.how-it-works a:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.how-it-works a:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.how-it-works .item { position: relative; }
.how-it-works .item .circle-left, .how-it-works .item .circle-right { background: url("../img/circles.png"); top: 59px; right: auto; bottom: auto; left: auto; position: absolute; width: 124px; height: 16px; }
.how-it-works .item .circle-left { left: -62px; }
.how-it-works .item .circle-right { right: -62px; }
.how-it-works .icon-box { background: #009eda; margin: 0 auto; height: 134px; width: 134px; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }
.how-it-works .icon-box .icon { color: white; }
.how-it-works .icon-box img { margin: auto; }

/* Why choose fonmoney */
.why-choose { background: #bee1fe; padding: 50px 0; }
.why-choose .row { margin-bottom: 0; }
.why-choose h2 { margin-top: 0; margin-bottom: 50px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.why-choose h5 { margin: 0; font-size: 18px; font-weight: 700; height: 50px; }
.why-choose img { margin: 31px 0; }
.why-choose p { margin: 0; padding: 0 12px; font-weight: 600; font-size: 14px; }

/* Transfer options */
.transfer-options { padding: 50px 0; background: #f9f9f9; }
.transfer-options .row { margin-bottom: 0; }
.transfer-options a { color: black; }
.transfer-options h2 { margin-top: 0; margin-bottom: 20px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.transfer-options h3 { font-size: 24px; font-weight: 700; margin-bottom: 25px; }
.transfer-options p { margin: 0 auto 30px; font-size: 18px; line-height: 18px; }
.transfer-options .item { font-family: "Open Sans", sans-serif; }
.transfer-options .item p { font-size: 16px; margin: 0; line-height: 30px; }

/* Operation countries */
.operation-countries { padding: 50px 0; }
.operation-countries .row { margin-bottom: 70px; }
.operation-countries h2 { margin-top: 0; margin-bottom: 20px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.operation-countries p { margin: 0 auto 60px; font-size: 18px; line-height: 18px; }
.operation-countries p.note { padding: 0 12%; margin-bottom: 0; font-size: 14px; }
.operation-countries a { font-size: 16px; font-weight: 600; display: block; padding-bottom: 24px; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }
.operation-countries a:hover { color: #0ebdff; }
.operation-countries .countries { margin: 0; }

/* Page */
.page-gray { background: #eee; padding: 50px 0 50px; }

/* Registration page */
.steps { margin: 0 auto 80px; text-align: center; display: table; }
.steps li { display: table-cell; vertical-align: middle; }
.steps li img { margin: 0 40px; }
.steps li:not(.step-divider) { background: white; color: #009eda; font-weight: 800; font-size: 18px; text-transform: uppercase; width: 134px; height: 134px; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; }
.steps li.active-step { background: #009eda; color: white; font-weight: 800; font-size: 18px; text-transform: uppercase; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; }
.steps li.checked { background: #a4c843; color: white; font-weight: 800; font-size: 24px; text-transform: uppercase; width: 134px; height: 134px; position: relative; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; }
.steps li.checked .check-icon { top: auto; right: 0; bottom: 0; left: auto; position: absolute; width: 35px; height: 35px; background: #fff url("../img/icons/check.png") no-repeat center center; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; }

.registration { padding-right: 2% !important; }
.registration h3 { font-size: 24px; line-height: 24px; font-weight: 600; margin: 0; }
.registration h5 { font-size: 18px; font-weight: 600; text-transform: uppercase; }
.registration label {font-size: 0.9rem;color: black;margin-left: 10px;}
.registration .social-login #sociallogin_button { width: 100%; }
.registration .social-login #sociallogin_button ul { width: 100%; }
.registration .social-login li { width: /*33.33%;*/ 100% }
.registration .social-login li:first-child { padding: 0; }
.registration .social-login .bt-login-social { text-indent: -9999px !important; width: 100%; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.registration .social-login .bt-login-social#bt-loginfb { background: #2d78bd url("../img/fb-reg.png") no-repeat center center !important; }
.registration .social-login .bt-login-social#bt-logingo { background: #dd4b39 url("../img/go-reg.png") no-repeat center center !important; }
.registration .social-login .bt-login-social#bt-loginya { background: #6f2b90 url("../img/ya-reg.png") no-repeat center center !important; }
.registration .social-login .bt-login-social span { height: 40px !important; background: none !important; }

.registration-form .input-field, .contact-form .input-field { margin-top: 0; }
.registration-form input[type="email"], .registration-form input[type="text"], .registration-form input[type="password"], .registration-form input[type="email"], .registration-form input[type="date"], .registration-form input[type="number"], .contact-form input[type="text"], .contact-form input[type="password"], .contact-form input[type="email"], .contact-form input[type="date"], .contact-form input[type="number"] { width: 92%; padding: 0 4%; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.registration-form textarea, .contact-form textarea { background: white; box-shadow: none; border: none; padding: 4%; width: 92%; min-height: 10em; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.registration-form textarea:focus, .contact-form textarea:focus { box-shadow: none !important; border: none !important; }
.registration-form input[type="submit"], .registration-form input[type="button"], .contact-form input[type="submit"], .contact-form input[type="button"], .info-validation input[type="button"],.continueLink { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; font-size: 18px; }
.registration-form input[type="submit"]:hover, .registration-form input[type="button"]:hover, .contact-form input[type="submit"]:hover, .contact-form input[type="button"]:hover, .info-validation input[type="button"]:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.registration-form input[type="submit"]:active, .registration-form input[type="button"]:active, .contact-form input[type="submit"]:active, .contact-form input[type="button"]:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.registration-form .radio-buttons, .contact-form .radio-buttons { padding: 0 40px; margin-bottom: 15px; }
.registration-form .radio-buttons span, .contact-form .radio-buttons span { margin-right: 20px; }
.registration-form .radio-buttons label, .contact-form .radio-buttons label { margin-right: 40px; }
.registration-form .picker__date-display, .registration-form .picker__day--selected, .registration-form .picker__day--selected:hover, .registration-form .picker--focused .picker__day--selected, .contact-form .picker__date-display, .contact-form .picker__day--selected, .contact-form .picker__day--selected:hover, .contact-form .picker--focused .picker__day--selected { background: #a4c843; }
.registration-form .picker__close, .registration-form .picker__today, .registration-form .picker__day.picker__day--today, .contact-form .picker__close, .contact-form .picker__today, .contact-form .picker__day.picker__day--today { color: #a4c843; color: #595959; }
.registration-form .picker__weekday-display, .contact-form .picker__weekday-display { background: #87a731; }
.registration-form button.picker__today:focus, .registration-form button.picker__clear:focus, .registration-form button.picker__close:focus, .registration-form .picker__nav--prev:hover, .registration-form .picker__nav--next:hover, .contact-form button.picker__today:focus, .contact-form button.picker__clear:focus, .contact-form button.picker__close:focus, .contact-form .picker__nav--prev:hover, .contact-form .picker__nav--next:hover { background: #b7d46a; }
.registration-form .select-wrapper input.select-dropdown, .contact-form .select-wrapper input.select-dropdown { padding: 0 10% !important; width: 80%; }

.registration-form .captcha-center,
#checkout-submit-form .captcha-center {
    justify-content: center;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.dropdown-content { max-height: 250px !important; }

.information { padding-left: 5% !important; margin-bottom: 0px; }
.information .wrapper { background: white; padding: 6%; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; -webkit-box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.25); -moz-box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.25); box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.25); }
.information .wrapper h5 { margin: 0; font-weight: 600; }

.confirmation { padding: 0 9% !important; margin-bottom: 80px; }
.confirmation h3 { font-size: 24px; line-height: 24px; font-weight: 600; }

/* Online banking, product choice */
.wrapper .intro { background: #eeeeee; }
.wrapper .intro.landing { background: #eeeeee; }
.wrapper .intro.blue-map { background: #eeeeee url("../img/map-blue.png") no-repeat center center; }
.wrapper .intro.blue-map-with-background { background: #bee1fe url("../img/map-blue.png") no-repeat center center; }
.wrapper .intro .item { position: relative; }
.wrapper .intro .item .circle-left, .wrapper .intro .item .circle-right { background: url("../img/circles.png"); top: 59px; right: auto; bottom: auto; left: auto; position: absolute; width: 124px; height: 16px; }
.wrapper .intro .item .circle-left { left: -62px; }
.wrapper .intro .item .circle-right { right: -62px; }
.wrapper .intro .item p { font-size: 24px; font-weight: 800; margin-top: 30px; margin-bottom: 0; color: #009eda; }
.wrapper .intro .receiver-info { margin-top: 70px; margin-bottom: 0; text-align: center; }
.wrapper .intro .receiver-info .receiver-fields { padding: 0 20%; }
.wrapper .intro .receiver-info input[type="email"] { -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; height: 55px; padding: 0 8%; width: 84%; }
.wrapper .intro .receiver-info input[type="submit"] { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; width: 100%; }
.wrapper .intro .receiver-info input[type="submit"]:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.wrapper .intro .receiver-info input[type="submit"]:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.wrapper .intro .receiver-info p { font-size: 18px; }
.wrapper .intro .checking { margin: 0px auto; position: relative; }


.wrapper .intro .checking h4 {
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px dashed #cfc4c4;
  color: #7f7b7b;
}

.wrapper .intro .checking .has-icon { position: relative; }
.wrapper .intro .checking .twitter-typeahead, .wrapper .intro .checking .tt-menu { width: 100%; }
.wrapper .intro .checking input[type="text"], .wrapper .intro .checking input[type="number"], .wrapper .intro .checking input[type="email"] {
  height: 55px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  width: 84%;
  padding: 0 8%;
}
.hooyu input[type="submit"] { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.wrapper .intro .checking input[type="submit"]  { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.wrapper .intro .checking input[type="submit"]:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.wrapper .intro .checking input[type="submit"]:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.wrapper .intro #precheck { background: #a4c843 none; margin: auto; display: block; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.wrapper .intro #precheck:hover { background: #8daf33 none; margin: auto; display: block; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.wrapper .intro #precheck:active { background: #77932b none; margin: auto; display: block; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.wrapper .intro .checking .input-icon { top: 10px; right: 20px; bottom: auto; left: auto; position: absolute; background: url("../img/icons/question.png") no-repeat center center; }
.wrapper .intro .checking .warning-container { top: 70px; right: auto; bottom: auto; left: auto; width: 100%; margin-left: 0; margin-top: 20px; }
.wrapper .intro .checking .warning-container .warning { -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21); color: #d90000; padding: 5%; overflow: initial; z-index: 1; }
.wrapper .intro .information h3 { font-size: 24px; line-height: 30px; font-weight: 600; margin: 0; }
.wrapper .intro .information p { font-size: 16px; margin: 0; }
.wrapper .intro .fields label { font-size: 18px; color: black; margin-bottom: 5px; display: inline-block; margin-left: 8%; }
@media screen and (max-width: 640px) {.wrapper .intro .fields label{margin-left:0%!important;}}
/*.wrapper .intro .fields input[type="text"] { -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; padding: 0 8%; width: 86%; }
.wrapper .intro .fields .select-wrapper { margin-top: 33px; }
.wrapper .intro .fields .select-wrapper input.select-dropdown { background: #a4c843 url("../img/icons/arrow-down-white.png") no-repeat center right 10px; color: white; }
.wrapper .intro .fields input[type="submit"] { background: #009eda none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; width: 100%; height: 43.5px; margin-top: 33px; }
.wrapper .intro .fields input[type="submit"]:hover { background: #0080b1 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.wrapper .intro .fields input[type="submit"]:active { background: #00678d none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }*/
.wrapper .intro .payment-methods { padding: 30px; }
.wrapper .intro .payment-methods h3 { font-size: 24px; margin-top: 0; line-height: 30px; font-weight: 700; }
.wrapper .intro .payment-methods p { margin-bottom: 10px; }
.wrapper .intro .payment-methods span { display: block; font-size: 14px; padding-left: 35px; }
.wrapper .intro .payment-methods [type="radio"] + label:before, .wrapper .intro .payment-methods [type="radio"] + label:after { margin-top: 10px; }
.wrapper .intro .payment-methods .checkbox { padding-left: 35px; }
.wrapper .intro .banking-list .icon-box { background: white; }

.available-products { background: #bee1fe; padding: 50px 0; }
.available-products h2 { margin-top: 0; margin-bottom: 0; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.available-products p { margin: 0 auto 20px; font-size: 18px; }
.available-products .item h3 { padding-left: 60px; font-size: 24px; font-weight: 700; font-family: "Open Sans", sans-serif; }
.available-products .item h3 a { color: black; }

/* Product choice */
.product-choice { width: 100% }
.product-choice a { font-weight: 700; }
.product-choice h2 { text-transform: uppercase; font-size: 30px; line-height: 30px; font-weight: 800; }
.product-choice .item:hover h5 a { color: #009eda; }
.product-choice .item:hover .icon-box { background: #009eda; }
.product-choice .item .icon { font-size: 5.5em; }
.product-choice .item .icon.icon-wallet { font-size: 3em; }

.product-card { display: flex; justify-content: center; }

.selected-country img { margin: 20px; }

.step-list { margin-top: 35px; }
.step-list h5 a { text-transform: uppercase; font-weight: 800; color: black; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }
.step-list .icon-box { margin: 0 auto; height: 134px; width: 134px; background: white; position: relative; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }
.step-list .icon-box:hover { background: #009eda; }
.step-list .icon-box:hover .icon { color: white; }
.step-list .icon-box.active { background: #009eda !important; }
.step-list .icon-box.active .icon { color: white; }
.step-list .icon-box.checked { background: #a4c843 !important; color: white; width: 134px; height: 134px; position: relative; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; }
.step-list .icon-box.checked .icon { color: white; }
.step-list .icon-box.checked .check-icon { top: auto; right: 0; bottom: 0; left: auto; position: absolute; width: 35px; height: 35px; background: #fff url("../img/icons/check.png") no-repeat center center; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; }
.step-list .icon-box img { margin: auto; }

/* Kyc upload */
.kyc-upload h5 { font-weight: 600; margin-top: 0; margin-bottom: 35px; }
.kyc-upload p { margin-top: 0; }
.kyc-upload .btn-large { width: 100%; height: 55px; line-height: 55px; background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; }
.kyc-upload .btn-large:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.kyc-upload .btn-large:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.kyc-upload .success { padding: 30px; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; width: 57%; margin-left: auto; margin-right: auto; margin-top: -2em;}
.kyc-upload .success .btn-large { width: 100%; }
.kyc-upload .file-meta { display: none; padding: 0 10px 0 70px; background: url("../img/icons/document.png") no-repeat center left 20px; height: 32px; line-height: 32px; }
.kyc-upload .filename { float: left; }
.kyc-upload .file-action { border: none; background: none; float: right; margin-top: 3px; }
.kyc-upload .select-wrapper input.select-dropdown { height: 55px; line-height: 55px; }
#identity-form label{position:absolute;top:-14px;font-size:0.8rem;outline: none;}
#identity-form select{display:block; outline: none; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; padding: 0 4%; height: 55px; -moz-appearance: none;font-size:15px;}
#identity-form select.browser-default{display:block;outline: none;}
#identity-form select:disabled{color:rgba(0,0,0,0.3);outline: none;}
#identity-form select:focus{display:block; outline: none;}
#identity-form div {margin-bottom: 8px;}
@media screen and (max-width: 640px) { #identity-form .btn-large { position: inherit; } }
.transfer-summary { padding: 30px; margin: 0; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; }
.transfer-summary h3 { font-size: 24px; margin-top: 0; line-height: 30px; font-weight: 700; }
.transfer-summary h5 { font-weight: 600; }
.transfer-summary .row:last-child { margin-bottom: 0; }
.transfer-summary .customer-info { color: #a4c843; font-weight: 700; font-size: 18px; }
.transfer-summary .total { font-weight: 700; font-size: 21px; line-height: 21px; }
.transfer-summary span { display: block; }
.transfer-summary .login, .transfer-summary .checkout { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; margin-top: 10px; width: 100%; }
.transfer-summary .login:hover, .transfer-summary .checkout:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.transfer-summary .login:active, .transfer-summary .checkout:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.transfer-summary .register { background: #009eda none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; margin-top: 10px; }
.transfer-summary .register:hover { background: #0080b1 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.transfer-summary .register:active { background: #00678d none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

#currencies {
  padding-top: 20px;
}

#currencies-mobile [type="radio"].with-gap:checked+label:before, #currencies-mobile [type="radio"].with-gap:checked+label:after {
  display: none;
}

#currencies-mobile [type="radio"]+label:before, #currencies-mobile [type="radio"]+label:after {
  display: none;
}

#currencies-mobile .btn-currencies-mobile input {
  display: none;
}

#currencies-mobile .btn-currencies-mobile label {
  padding-left: 0px !important;
}

#currencies-mobile .btn-currencies-mobile-selected {
  background-color: #a4c843;
}

#currencies-mobile .btn-currencies-mobile-selected label {
  color: white !important;
}

#co-payment-form .mobile-currency {
  display:none;
}

#currencies-mobile .col {
  padding: 0;
}

#currencies-mobile {
  width: 60% !important;
  padding: 6px !important;
  text-align: center !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  border-radius: 0px !important;
  position: absolute;
  height: 100%;
  z-index: 999999999999999999;
}

#checkout-currencies-form-mobile .modal-close {
  margin-bottom: 5px;
  display: flex;
}

#checkout-currencies-form-mobile .modal-close:last-child{
  margin-bottom: 0px;
}

#currencies-mobile .btn-currencies-mobile {
  width: 100%;
  margin-bottom: 0px;
  padding: 10px;
  text-align: center;
  border: 1px dotted #a4c843;
}

#currencies-mobile .btn-currencies-mobile label {
  font-weight: bold;
  color: black;
  letter-spacing: 1px;
}

#currencies-mobile span {
  margin-top: 0.8em;
}

#payment-options-mobile, #payment-options-mobile ul, #payment-options-mobile ul li, #payment-options-mobile fieldset {
  margin-bottom: 0px;
  margin-top: -10px;
}

.transfer-summary .agree {
  margin-top: 0px;
  margin-bottom: 0px;
}

#checkout-agreements{
  margin-left: -37px;
}

@media screen and (max-width: 640px) {

  .hidden {
    display: none !important;
  }

  .transfer-summary form p, .transfer-summary-totals {
    margin-bottom: 20px;
    text-align: left;
    border: 1px dotted #a4c843;
    padding: 10px;
    border-radius: 0px !important;
  }
  .transfer-summary form p img { width: 50%; margin-top: 6px; }

  .transfer-summary .total { color: #000; font-weight: 700; font-size: 14px; line-height: 21px; }

  #co-payment-form-mobile .mobile-currency {
    display:none; margin-top: 10px; margin-bottom: 0px;
  }

  .intro .valign-wrapper {
    margin-top: 0px !important;
  }

  .step-list .valign {
    margin-top: 0px !important;
  }

  fieldset {
    padding: 0 0.625em 0;
  }

  .transfer-summary .login, .transfer-summary .checkout, .transfer-summary .register {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
  }

  #payment-options-mobile, #payment-options-mobile ul, #payment-options-mobile ul li, #payment-options-mobile fieldset {
    margin-bottom: 0px;
    margin-top: -15px;
  }

}

.trustpilot { padding: 22px 30px; margin: 0; -webkit-border-radius: 20px; -moz-border-radius: 20px; border-radius: 20px; }

.success-info, .unsuccess-info { overflow: auto; display: table; margin: 20px auto 40px; }
.success-info .invalid, .unsuccess-info .invalid { margin-left: 30px; }
.success-info h5, .unsuccess-info h5 { float: left; margin: 0; }
.success-info p, .unsuccess-info p { float: left; margin: 0; }
.success-info img, .unsuccess-info img { margin-right: 30px; float: left; }

/* Call to action */
.call-to-action { padding: 50px 0; }
.call-to-action h5 { margin: 0; padding: 0; }
.call-to-action a { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; width: 100%; }
.call-to-action a:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.call-to-action a:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

/* Top up step */
.choosing { text-align: center; }
.choosing ul { margin: 0; display: inline-block; }
.choosing ul li:hover, .choosing ul li.active { background: #a4c843; cursor: pointer; color: white; }

.top-up-selection { font-size: 18px; }

.center-field { justify-content: center; width: 100%; display: flex; margin-bottom: 10px; }

.continue { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px;
  border-radius: 30px}
.continue:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.continue:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

.youtube-video { padding-left: 5% !important; }

/* My account */
.my-account h5 { margin-bottom: 20px; }

.list { max-height: 390px; overflow-y: scroll; overflow-x: hidden; }
.list li { border-bottom: 1px dashed #ccc; padding: 0 10px 10px 0; }
.list li:last-child { margin-bottom: 0; }
.list h5 { font-size: 18px; margin-top: 0; }
.list h5 span { font-size: 14px; line-height: 14px; display: block; }
.list a { margin-right: 10px; }
.list a:last-child { margin: 0; }

#list_transaction_history, #list_online_banking {
  margin-bottom:60px;
  overflow-y:hidden;
}

#banking_actual_list {
  max-height:500px;
  border-bottom: 2px dashed #E0DCDC;
  border-top: 2px dashed #E0DCDC;
}

#transaction_history_actual_list {
  border-bottom: 2px dashed #E0DCDC;
  border-top: 2px dashed #E0DCDC;
}

#banking_actual_list li {
  height:160px;
}

.hlistnav {
  text-align:center;
  margin:20px 0;
  display:none;
}

.hlistnav > span {
  cursor:pointer;
  display:inline-block;
  width:40px;
  height:40px;
  background-size:contain;
  background:url('../img/arrow.svg') center center no-repeat;
}

.godown-inner {
  transform:rotate(180deg);
}

.transaction-history li .col, .banking li .col { margin-right: 15px; }
.transaction-history li .col:last-child, .banking li .col:last-child { margin: 0; }
.transaction-history li .btn, .banking li .btn { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; height: 40px; line-height: 40px; width: 100%; }
.transaction-history li .btn:hover, .banking li .btn:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.transaction-history li .btn:active, .banking li .btn:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }


.my-history-buttons .btn { background: #039be5 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; height: 40px; line-height: 40px; width: auto; }
.my-history-buttons-extra .btn { text-align: center; background: #039be5 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; height: 40px; line-height: 40px; width: auto; }

@media screen and (max-width: 640px){
  .my-history-buttons, .my-history-buttons-extra { text-align: center; margin: auto; width: 100%; }
  .my-history-buttons { width: 100%; }
  #banking_actual_list .row .col .s6 { width: 100%; }
  #banking_actual_list .row .row .right { width: 100%; float: left !important; }
  .account-title { background: none !important; padding-left: 0px !important; }
  .transaction-history .row .right { width: 100%; float: left !important; }
  .transaction-history li .btn, .banking li .btn { border-radius: 0px; margin: 5px; left: 0; }
  .my-history-buttons-extra .btn { width: 98%; border-radius: 0px; float: left; }
  #banking_actual_list .my-history-buttons-extra .btn { width: 100%; }
  #banking_actual_list .my-history-buttons-extra { padding-top: 5px !important; float: left; }
}

@media screen and (min-width: 640px){
  .my-history-buttons, .my-history-buttons-extra { text-align: center; margin: auto; width: 40%; }
  .transaction-history li.row div.col.m3 { width: 23%; }
  .my-history-buttons, .my-history-buttons-extra { text-align: center; margin: auto; width: 100%; }
}

/* Imprint page */
.imprint h3 { font-size: 24px; }
.imprint p { margin-bottom: 5px; font-size: 16px; }

/* Contact page */
.contact h5 { font-size: 20px; }
.contact .sub-title { font-size: 24px; margin: 0px auto 90px; line-height: 1; }
.contact .captcha-center { justify-content: center; width: 100%; display: flex; margin-bottom: 10px; }

/* FAQ page */
.faq h5 { color: #009eda; cursor: pointer; display: inline-block; }
.faq p { margin-top: 0px; display: none; margin: 40px; }

/* Pricing table */
.pricing-table { padding: 50px 0; background: #f9f9f9; }
.pricing-table h2 { margin-top: 0; margin-bottom: 0px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.pricing-table p { font-size: 18px; }
.pricing-table a { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; padding: 15px 30px; margin-top: 20px; display: inline-block; width: 240px; font-size: 18px; text-transform: uppercase; }
.pricing-table a:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.pricing-table a:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

.customer-reviews .testimonials { padding: 0px !important; }

/* Video */
.our-video { background: #bee1fe; padding: 50px 0; }
.our-video h2 { margin-top: 0; margin-bottom: 0px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.our-video p { font-size: 18px; font-weight: 300; }
.our-video .video-container { height: 500px; }
.our-video .video-container iframe { width: 80%; margin: auto; left: 0; right: 0; }

/* Team */
.team { background: white; padding: 20px 0; }
.team .row-header { text-align: center; margin-bottom: 20px; padding-bottom: 20px; border-bottom: 1px dashed #ccc; }
.team .row-header h1 { font-size: 24px; margin-top: 0; }
.team .row-header p { font-size: 24px; font-weight: normal; }
.team .row-header span { color: #009eda; font-size: 18px; font-style: italic; font-weight: normal; }
.team .member { margin-bottom: 50px; }
.team .member .member-image { width: 30%; float: left; margin-top: 50px; }
.team .member .member-image img.avatar { border: 10px solid #bde0fd; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; }
.team .member .member-image a { margin-top: 30px; text-align: center; display: block; }
.team .member .member-details { width: 70%; float: left; padding: 0 5%; }
.team .member .member-details h3 { margin: 0; font-weight: 600; font-size: 24px; line-height: 28px; }
.team .member .member-details p { font-size: 18px; font-weight: normal; }

/* Philosophy */
.philosophy, .join-network { padding: 50px 0; }
.philosophy h3, .join-network h3 { margin: 0 auto 30px; padding: 0; font-size: 24px; line-height: 24px; }
.philosophy p, .join-network p { margin-bottom: 0; font-weight: normal; font-size: 18px; }

/* Cubacel */
.cubacel h3 { font-size: 24px; font-weight: 600; }
.cubacel p { font-size: 18px; font-weight: normal; }

.amounts { padding: 50px 0; background: #f9f9f9; }
.amounts h2 { margin-top: 0; margin-bottom: 0px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.amounts table {min-width: 280px;max-width: 380px;; margin: 40px auto 0; font-weight: 300; box-shadow: 0px 2px 10px #bbb; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; overflow: hidden; }
.amounts table thead { color: white; background: #009eda; }
.amounts table thead tr { -webkit-border-radius: 30px 30px 0 0; -moz-border-radius: 30px 30px 0 0; border-radius: 30px 30px 0 0; }
.amounts table thead tr th { font-weight: 300; text-transform: uppercase; }
.amounts table tbody { background: white; padding-bottom: 20px; }
.amounts table tr:nth-child(even) { background: #efefef; }
.amounts table tr th, .amounts table tr td { text-align: center; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; }

/* Follow us */
.follow-us { background: white; padding: 50px 0; }
.follow-us h2 { margin-top: 0; margin-bottom: 0px; font-size: 30px; line-height: 30px; text-transform: uppercase; font-weight: 800; }
.follow-us p { margin-top: 5px; }

/* Prepaid information */
.prepaid-information { background: #bee1fe; padding-top: 40px; }
.prepaid-information h3 { font-size: 30px; font-weight: 800; text-transform: uppercase; }
.prepaid-information p { font-size: 18px; font-weight: normal; padding: 0 30px; }
.prepaid-information .sites-container { padding: 40px 0; }
.prepaid-information .sites-container ul.sites { margin-bottom: 20px; margin-top: 0; }
.prepaid-information .sites-container ul.sites li { display: inline-block; margin: 0 5px; font-weight: normal; }
.prepaid-information .sites-container ul.sites li b { font-weight: 700; }

/* Advisors */
.advisors { background: white; padding: 20px 0; }
.advisors h1 { text-align: center; font-size: 24px; margin-bottom: 50px; }
.advisors .advisor { margin-bottom: 40px; }
.advisors .advisor .advisor-image { width: 15%; float: left; }
.advisors .advisor .advisor-details { float: left; width: 85%; padding-left: 5%; }
.advisors .advisor .advisor-details h3 { padding: 0; margin: 0; font-size: 24px; font-weight: 600; }
.advisors .advisor .advisor-details p { margin-bottom: 0; font-size: 18px; font-weight: 300; }

/* Our steps */
.our-steps { text-align: center; padding-bottom: 40px; }
.our-steps .step .step-image { min-height: 100px; position: relative; }
.our-steps .step .step-image img { top: 0; right: 0; bottom: 0; left: 0; position: absolute; margin: auto; }
.our-steps .step h3 { font-size: 24px; font-weight: 800; margin-bottom: 0; }
.our-steps .step p { margin-top: 0; }

/* How works */
.how-works h3 { font-size: 24px; margin: 0; }
.how-works .step-list .item { position: relative; }
.how-works .step-list .icon-box { background: transparent; }
.how-works .step-list h3 { font-size: 24px; font-weight: 700; margin-top: 10px; }
.how-works .step-list .circle-left, .how-works .step-list .circle-right { background: url("../img/circles-blue.png"); top: 59px; right: auto; bottom: auto; left: auto; position: absolute; width: 124px; height: 16px; }
.how-works .step-list .circle-left { left: -62px; }
.how-works .step-list .circle-right { right: -62px; }

/* Our story */
.our-story h5 { font-size: 24px; font-weight: 600; }
.our-story p { font-size: 16px; font-weight: normal; }

/* Promotions */
.promotions h3 { font-size: 30px; font-weight: 800; margin: 0 auto 10px; padding: 0; }
.promotions h4 { font-size: 24px; font-weight: 700; margin: 0; }
.promotions .text-container { width: 100%; }
.promotions .text-container h4 { width: 50%; padding-right: 10px; text-align: right; margin-bottom: 10px; }
.promotions span { display: block; }
.promotions img { margin-top: 40px; }
.promotions p { font-size: 18px; }
.promotions small { font-size: 14px; }

/* Logged out */
.logged-out { text-align: center; }
.logged-out h2 { text-transform: uppercase; font-weight: 600; }
.logged-out p { font-style: 18px; }

/* Landing marketing */
.landing-marketing h2 { font-size: 18px; line-height: 18px; text-transform: uppercase; font-weight: 800; text-align: center; }
.landing-marketing .how-to { padding: 0 6%; }
.landing-marketing .table { margin: 40px auto 0; font-weight: 300; box-shadow: 0px 2px 10px #bbb; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; overflow: hidden; }
.landing-marketing .table thead { color: white; background: #009eda; }
.landing-marketing .table thead tr { -webkit-border-radius: 30px 30px 0 0; -moz-border-radius: 30px 30px 0 0; border-radius: 30px 30px 0 0; }
.landing-marketing .table thead tr th { font-weight: 300; text-transform: uppercase; }
.landing-marketing .table tbody { background: white; padding-bottom: 20px; }
.landing-marketing .table tbody tr:nth-child(even) { background: #efefef; }
.landing-marketing .table tr th, .landing-marketing .table tr td { text-align: center; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; }

/* Landing marketing mobile */
@media screen and (max-width: 375px){
  .currency{ display: block; }
  .landing-marketing .table { width: 100% !important; }
  .intro .landing-text h1 span{ display: none !important; }
  .intro .landing-text .marketing input[type="text"] { width: 85% !important; }
  .intro .landing-text .marketing input[type="submit"] { width: 100% !important; margin-right: 0 !important; max-width: 100% !important; padding: 0 !important; }
  .how-to, .follow-us-facebook { display: none !important; }
  .intro .landing-text h1 {  margin-bottom: 20px; }
}

/* Landing online banking V2 */
.landing-online-banking p, .landing-online-topup p { font-size: 18px; }
.landing-online-banking .pricing-table, .landing-online-topup .pricing-table { background: transparent; text-align: center; }
.landing-online-banking .table, .landing-online-topup .table { width: 280px; margin: 40px auto 0; font-weight: 300; box-shadow: 0px 2px 10px #bbb; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; overflow: hidden; }
.landing-online-banking .table thead, .landing-online-topup .table thead { color: white; background: #009eda; }
.landing-online-banking .table thead tr, .landing-online-topup .table thead tr { -webkit-border-radius: 30px 30px 0 0; -moz-border-radius: 30px 30px 0 0; border-radius: 30px 30px 0 0; }
.landing-online-banking .table thead tr th, .landing-online-topup .table thead tr th { font-weight: 300; text-transform: uppercase; }
.landing-online-banking .table tbody, .landing-online-topup .table tbody { background: white; padding-bottom: 20px; }
.landing-online-banking .table tbody tr:nth-child(even), .landing-online-topup .table tbody tr:nth-child(even) { background: #efefef; }
.landing-online-banking .table tr th, .landing-online-banking .table tr td, .landing-online-topup .table tr th, .landing-online-topup .table tr td { text-align: center; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; }

.send-money h2 { font-size: 30px; font-weight: 800; text-transform: uppercase; }
.send-money h3 { font-size: 24px; font-weight: 600; margin-top: 30px; }

/* Blog */
.sub-title { font-size: 18px; text-transform: initial; display: table; line-height: 0px; font-weight: 300; }

.blog { background: white; padding: 50px; margin-top: 40px; }
.blog .content { padding-right: 5%; border-right: 1px dashed black; }
.blog .content .post { margin-bottom: 50px; }
.blog .content .post:last-child { margin-bottom: 0; }
.blog .content .post .post-meta { position: relative; }
.blog .content .post .post-meta ul { margin: 0; }
.blog .content .post .post-meta ul li { border-right: 1px solid #000; display: inline-block; color: #7f7f7f; }
.blog .content .post .post-meta ul li.author { padding: 0 20px 0 50px; }
.blog .content .post .post-meta ul li.author .social { margin-left: 10px; }
.blog .content .post .post-meta ul li.author .social.twitter { position: relative; top: 2px; }
.blog .content .post .post-meta ul li.author .author-image { width: 36px; height: 36px; position: absolute; left: 0; top: -7px; border: 3px solid #bee1fe; -webkit-border-radius: 100%; -moz-border-radius: 100%; border-radius: 100%; }
.blog .content .post .post-meta ul li.posted { padding: 0px; border-right: 0; }
.blog .content .post .post-title a { color: #000; }
.blog .content .post .post-title a:hover { color: #009eda; }
.blog .content .post .post-title a h1 { font-size: 30px; line-height: 34px; }
.blog .content .post .post-excerpt p { font-size: 16px; display: inline-block; width: 100%; color: #7f7f7f; }
.blog .content .post .post-share ul { text-align: right; }
.blog .content .post .post-share ul li { display: inline; }
.blog .content .post .post-share ul li a { display: inline-block; padding: 10px 5px; font-size: 12px; text-transform: capitalize; font-weight: 300; margin: 0; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }
.blog .content .post .post-share ul li a.twitter { background-color: #55acee; background-image: url("../img/share/twitter.png"); color: transparent; background-position: center center; background-repeat: no-repeat; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.twitter:hover { padding: 10px 30px 10px 50px; color: white; background-position: left 10px center; text-align: right; width: auto; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.facebook { background-color: #3b5998; background-image: url("../img/share/facebook.png"); color: transparent; background-position: center center; background-repeat: no-repeat; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.facebook:hover { padding: 10px 30px 10px 50px; color: white; background-position: left 10px center; text-align: right; width: auto; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.googleplus { background-color: #dd4b39; background-image: url("../img/share/googleplus.png"); color: transparent; background-position: center center; background-repeat: no-repeat; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.googleplus:hover { padding: 10px 30px 10px 50px; color: white; background-position: left 10px center; text-align: right; width: auto; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.linkedin { background-color: #0976b4; background-image: url("../img/share/linkedin.png"); color: transparent; background-position: center center; background-repeat: no-repeat; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.linkedin:hover { padding: 10px 30px 10px 50px; color: white; background-position: left 10px center; text-align: right; width: auto; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.email { background-color: #ff4020; background-image: url("../img/share/email.png"); color: transparent; background-position: center center; background-repeat: no-repeat; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .post-share ul li a.email:hover { padding: 10px 30px 10px 50px; color: white; background-position: left 10px center; text-align: right; width: auto; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
.blog .content .post .card-action { padding: 0 30px; }
.blog .content .post.single-post { margin-bottom: 0; }
.blog .content .post.single-post .post-content { font-size: 16px; color: #000000; }
.blog .content .post.single-post .author-box { background: #009eda; color: white; padding: 10px 20px; }
.blog .content .post.single-post .author-box img.author-image { width: 100px; margin: 50px auto; border: 5px solid #bee1fe; border-radius: 100%; }
.blog .content .post.single-post .author-box .author-information ul { border-top: 1px dashed white; padding-top: 10px; margin-top: 10px; }
.blog .content .post.single-post .author-box .author-information ul li { float: left; margin-right: 5px; font-weight: 300; font-size: 16px; }
.blog .content .post.single-post .author-box .author-information ul li img.twitter { margin-left: 15px; margin-right: 10px; position: relative; top: 2px; }
.blog .content .post.single-post .author-box .author-information ul li:last-child { float: right; }
.blog .content .post.single-post .author-box .author-information ul li:last-child b { font-weight: 600; }
.blog .content .post.single-post .more .tabs { margin: 30px auto; height: 70px; box-shadow: inset 0px -5px 0px #333; }
.blog .content .post.single-post .more .tabs .tab a { color: black; font-size: 21px; text-transform: capitalize; }
.blog .content .post.single-post .more .tabs .tab a.active { color: #009eda; }
.blog .content .post.single-post .more .tabs .indicator { height: 5px; background-color: #009eda; }
.blog .content .post.single-post .more .card a.card-title { color: #009eda; font-size: 24px; font-weight: 600; margin-bottom: 10px; display: inline-block; line-height: 30px; }
.blog .content .post.single-post .see-more a { font-size: 18px; font-weight: 600; }
.blog .content .post.single-post .see-more a img { margin-left: 5px; position: relative; top: -2px; }
.blog .sidebar .widget { border-bottom: 1px dashed black; margin-bottom: 20px; padding-bottom: 20px; }
.blog .sidebar .widget h1 { font-size: 24px; line-height: 24px; margin: 0; }
.blog .sidebar .widget ul { margin-bottom: 0; }
.blog .sidebar .widget.search input { background: url("../img/icons/search-icon.png") no-repeat center right 10px; border: 1px solid #ccc; padding: 0 5%; height: 50px; width: 90%; margin: 0; }
.blog .sidebar .widget.recent-articles ul li { margin-bottom: 10px; }
.blog .sidebar .widget.recent-articles ul li:last-child { margin-bottom: 0; }
.blog .sidebar .widget.categories ul li { margin-bottom: 10px; font-size: 16px; }
.blog .sidebar .widget.categories ul li:last-child { margin-bottom: 0; }
.blog .sidebar .widget.tags ul li a { margin-right: 10px; line-height: 36px; display: inline-block; font-size: 16px; }

/* Footer */
.footer { padding: 50px 0; }
.footer .row { margin-bottom: 0; }

.footer-menu { margin: 0; }
.footer-menu .row { margin-bottom: 0; }
.footer-menu li { display: block; }
.footer-menu li a { font-size: 14px; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }
.footer-menu li a:hover { color: black; }

.social-icons { margin: 0; }
.social-icons li { display: inline-block; margin: 9px 2px 0; }

/* Copyright */
.copyright { padding: 20px 0; }
.copyright .row { margin-bottom: 0; }
.copyright span { font-size: 14px; font-weight: 600; padding-top:6px; display:inline-block; }
.copyright img.payment-options { margin: 10px auto 0; display: block; }

.product-additional-info-box {
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #ffffff;
    background: #4a6aa0;
    margin: 0px 5% 5% 25%;
    overflow: hidden;
    padding: 15px;
    border-radius: 10px;
}

.product-additional-info-box a {
    color: #FFF;
    font-weight: normal;
    text-decoration: underline;
    font-size: 18px;
}

@media all and (max-width: 640px) {
    .box { margin-left: 5%; }
  }

.product-promotional-box {
    min-height: 40px;
    border-radius: 10px;
    -webkit-box-shadow: 3px 3px 15px 7px rgba(0, 0, 0, .16);
    -moz-box-shadow: 3px 3px 15px 7px rgba(0, 0, 0, .16);
    box-shadow: 4px 5px 20px 8px rgba(0, 0, 0, .25);
    background-color: #fff;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 10px;
}

.box-receiver {
    margin: 0px 0% 5% 0%;
    overflow: hidden;
}

    .promotion-color{
      background-color: #5a7bcd !important;
      padding-left: 35% !important;
    }

    @media all and (max-width: 640px){
      .promotion-color{
        padding-left: 20% !important;
      }
    }

/* Titles */
.online-banking-title {
  font-size: 30px;
  font-weight: 800;
  margin: 0 auto 1.68rem;
  text-transform: uppercase;
  display: table; margin: 0px auto 40px;
  text-align:center;
}

.registration-title { font-size: 30px; font-weight: 800; margin: 0 auto; /* padding-left: 70px; */ text-transform: uppercase; /* background: url(../img/icons/registration-lock-icon.png) no-repeat center left; */ display: table; /*margin: 0px auto 40px;*/ }

.kyc-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; }

.topup-title { line-height: 40px; font-size: 30px; font-weight: 800; margin: auto; text-transform: uppercase; display: table; }

.topup-sub-title { font-size: 18px !important; margin: 0 auto 1.68rem; display: table; margin: 0px auto 10px; }

.account-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; }

.account-title-history-page { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 0px; }

.transaction-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; margin: 0px auto 40px; margin: 0 0 20px; }

.banking-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase;  margin: 0px auto 40px; }

.imprint-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; margin: 0 auto 0px; }

.contact-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; }

.faq-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; margin: 0 auto 10px; }

.blog-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; margin: 0 auto 10px; }

.ayden-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; margin: 0 auto 10px; }

.aboutus-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; margin: 0 auto 0px; }

.promotions-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; }

.how-it-works-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; margin: 0 auto 20px; }

.cubacel-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; margin: 0 auto 0; }

.minifaq-title { font-size: 30px; font-weight: 800; margin: 0 auto 1.68rem; text-transform: uppercase; display: table; margin: 0px auto 40px; margin: 0 auto 20px; font-weight: normal; text-transform: initial; line-height: 60px; display: block; font-size: 24px; }

/* Helpers */
.divider-dashed { border: 1px dashed #ccc; margin: 40px 0; }

.green-text { color: #a4c843 !important; }

.blue-text { color: #009eda !important; }

.light-green { background-color: #a4c843 !important; }

.light-blue { background-color: #009eda; }

#order-info .btn, #order-info .btn-large { text-decoration: none; color: #FFF;  text-align: center; letter-spacing: .5px; -webkit-transition: .2s ease-out; -moz-transition: .2s ease-out; -o-transition: .2s ease-out; -ms-transition: .2s ease-out; transition: .2s ease-out; cursor: pointer; background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; width: 100%; }o-icon { background: none !important; padding: 0 !important; margin: 0 !important; display: inline-block !important; }

.btn-continue { margin-top:15px; background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s;  -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; -webkit-border-radius: 30px; height: 54px; line-height: 56px; border: none; padding: 0 2rem; text-transform: uppercase; }

#order-info .btn:hover, #order-info .btn-large:hover { background-color: #8DAF33; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;  }

.btn, .btn-large, .btn:hover, .btn-large:hover { -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

.no-icon { background: none !important; padding: 0 !important; margin: 0 !important; display: inline-block !important; }

.no-padding-top { padding-top: 0; }

.no-padding-bottom { padding-bottom: 0; }

.pad-side0 { padding-left: 0; padding-right: 0; }

.p20 { padding: 20px 0; }

.pt40 { padding-top: 40px; }

.m0, .card { margin: 0; }

.m10 { margin-top: 10px; }

.m40 { margin: 40px 0; }

.image-right { float: right; }

.divider.dashed { border-bottom: 1px dashed #ccc; width: 100%; background: transparent; display: block; }

.info-icon { top: auto; right: 0; bottom: 0; left: auto; position: absolute; width: 35px; height: 35px; background: url("../img/icons/question-white.png"); }

ul.check li { background: url("../img/icons/check.png") no-repeat top 5px left; padding-left: 40px; margin-bottom: 20px; }
ul.check li:last-child { margin-bottom: 0; }

.quote { font-size: 24px; font-weight: 300; text-align: center; }
.quote span { font-style: italic; color: #009eda; font-size: 18px; display: block; margin-top: 20px; }

ul.messages { max-width: 1000px; margin: 20px auto; text-align: center; color: #333; font-weight: bold; width: 100%; }

/* Loading */
.load-more { overflow: hidden; -webkit-transition: all .2s ease, background-color .01s ease, color .01s ease; transition: all .2s ease, background-color .01s ease, color .01s ease; }

.load-more.load-more--loading { -webkit-animation: rotate 1.5s linear infinite; animation: rotate 1.5s linear infinite; -webkit-animation-delay: .2s; animation-delay: .2s; background-color: transparent; border: .3em solid white; border-radius: 2em; border-top-color: #a4c843; box-sizing: border-box; height: 55px; color: transparent !important; padding: 0; pointer-events: none; width: 55px !important; background-color: transparent !important; }

@-webkit-keyframes rotate { 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } }
@keyframes rotate { 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } }
/* Circle preloader */
.loading-container { float: left; margin-right: 20px; }

.loader { position: relative; width: 80px; height: 80px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }

.loader-text { line-height: 80px; }

.loader-bg { width: 100%; height: 100%; border-radius: 50%; border: 8px solid #ccc; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }

.spiner-holder-one { top: 0; right: auto; bottom: auto; left: 0; position: absolute; overflow: hidden; width: 50%; height: 50%; background: transparent; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }

.spiner-holder-two { top: 0; right: auto; bottom: auto; left: 0; position: absolute; overflow: hidden; width: 100%; height: 100%; background: transparent; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }

.loader-spiner { width: 200%; height: 200%; border-radius: 50%; border: 8px solid #a4c843; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }

.animate-0-25-a { -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -o-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg); -webkit-transform-origin: 100% 100%; -moz-transform-origin: 100% 100%; -o-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; }

.animate-0-25-b { -webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg); -o-transform: rotate(-90deg); -ms-transform: rotate(-90deg); transform: rotate(-90deg); -webkit-transform-origin: 100% 100%; -moz-transform-origin: 100% 100%; -o-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; }

.animate-25-50-a { -webkit-transform: rotate(180deg); -moz-transform: rotate(180deg); -o-transform: rotate(180deg); -ms-transform: rotate(180deg); transform: rotate(180deg); -webkit-transform-origin: 100% 100%; -moz-transform-origin: 100% 100%; -o-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; }

.animate-25-50-b { -webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg); -o-transform: rotate(-90deg); -ms-transform: rotate(-90deg); transform: rotate(-90deg); -webkit-transform-origin: 100% 100%; -moz-transform-origin: 100% 100%; -o-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; }

.animate-50-75-a { -webkit-transform: rotate(270deg); -moz-transform: rotate(270deg); -o-transform: rotate(270deg); -ms-transform: rotate(270deg); transform: rotate(270deg); -webkit-transform-origin: 100% 100%; -moz-transform-origin: 100% 100%; -o-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; }

.animate-50-75-b { -webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg); -o-transform: rotate(-90deg); -ms-transform: rotate(-90deg); transform: rotate(-90deg); -webkit-transform-origin: 100% 100%; -moz-transform-origin: 100% 100%; -o-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; }

.animate-75-100-a { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); -ms-transform: rotate(0deg); transform: rotate(0deg); -webkit-transform-origin: 100% 100%; -moz-transform-origin: 100% 100%; -o-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; }

.animate-75-100-b { -webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg); -o-transform: rotate(-90deg); -ms-transform: rotate(-90deg); transform: rotate(-90deg); -webkit-transform-origin: 100% 100%; -moz-transform-origin: 100% 100%; -o-transform-origin: 100% 100%; -ms-transform-origin: 100% 100%; transform-origin: 100% 100%; }

/* Custom scroll */
#scrollbox3 { overflow: auto; width: 400px; height: 360px; padding: 0 5px; border: 1px solid #b7b7b7; }

.track3 { width: 7px; background: transparent; border-radius: 10px; -webkit-transition: background 250ms linear; transition: background 250ms linear; }

.track3:hover, .track3.dragging { background: #d9d9d9; /* Browsers without rgba support */ background: rgba(0, 0, 0, 0.15); }

.handle3 { width: 7px; right: 0; background: #999; background: rgba(0, 0, 0, 0.4); border-radius: 7px; -webkit-transition: width 250ms; transition: width 250ms; }

.track3:hover .handle3, .track3.dragging .handle3 { width: 7px; }

/* Custom placeholder color */
::-webkit-input-placeholder { color: #999; }

:-moz-placeholder { /* Firefox 18- */ color: #999; }

::-moz-placeholder { /* Firefox 19+ */ color: #999; }

:-ms-input-placeholder { color: #999; }

#outdated { display: none; }

.icon { margin: auto; font-size: 4em; color: #009eda; display: inline-block; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }

/* Custom css - fonmoney */

.landing-marketing .table { margin: 40px auto 0; font-weight: 300; box-shadow: 0px 2px 10px #bbb; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; overflow: hidden; width: 380px; }

.choosing ul li { margin: 5px 15px; text-align: center; }

.choosing ul li { background: #009eda; color: black; font-size: 30px; font-weight: 700; margin: 0 10px; min-width: 150px; height: 55px; line-height: 55px; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; }

.how-it-works a { margin-top: 135px; min-width: 300px; height: 55px; font-weight: 600; font-size: 18px; background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; -webkit-border-radius: 40px; -moz-border-radius: 40px; border-radius: 40px; }
.how-it-works a:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.how-it-works a:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

.landing-text input[type="submit"] { background: #a4c843 none; text-decoration: none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; color: #FFF; text-align: center; letter-spacing: .5px; cursor: pointer; -webkit-transition: 0.3s; -moz-transition: 0.3s; -o-transition: 0.3s; -ms-transition: 0.3s; transition: 0.3s; float: left; height: 55px; text-transform: uppercase; border: none; min-width: 120px; -webkit-border-radius: 30px; -moz-border-radius: 30px; border-radius: 30px; padding-left: 1.2em;}

@media screen and (max-width: 640px) {
  .landing-text input[type="submit"] { padding-left: 1.5em; }
}

.landing-text input[type="submit"]:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
.landing-text input[type="submit"]:active { background: #77932b none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }

/* Custom mobile dropdown - checkout */
.payment-dropdown { position: relative; margin-bottom: 30px; }
.payment-dropdown a { display: block; border: 1px solid #ccc; padding: 10px 20px; border-radius: 30px; color: black; background: #fff url("../img/icons/arrow-down-green.png") no-repeat center right 10px; }
.payment-dropdown ul { background: white;  padding: 10px; width: 100%; z-index: 1; }
.payment-dropdown ul:before { position: absolute; content: ""; width: 0; height: 0; border-style: solid; border-width: 0 10px 10px 10px; border-color: transparent transparent #ffffff transparent; top: -10px; right: 14px; z-index: 2; }
.payment-dropdown ul:after { position: absolute; top: -12px; right: 12px; content: ''; width: 0; height: 0; border-left: 12px solid transparent; border-right: 12px solid transparent; border-bottom: 12px solid rgba(0, 0, 0, 0.09); z-index: 1; }
.payment-dropdown ul li { padding: 10px 20px; }
.payment-dropdown ul li.active { background-color: #a4c843; }
.ds-banner div a img { height: 181px; width: 885px; }

/* Smartphones (portrait and landscape) ----------- */
@media screen and (max-width: 640px) { .country-slider, .top-menu, .main-menu, .top-header, .slick-prev, .slick-next, .how-works .step-list .circle-left, .how-works .step-list .circle-right { display: none !important; }
  .hidden-logo { opacity: 1; visibility: visible; transform: none; margin-top: 12px; }
  .side-nav { background: #FFFFFF; }
  .side-nav a { color: #185795 !important; text-transform: uppercase; font-weight: 700; }
  .side-nav li:hover, .side-nav li.active {  background: #185795; }
  .side-nav li:hover a, .side-nav li.active a {  color: #FFFFFF !important; }
  .side-nav .collapsible-body { background: #0079a7; }
  .mobile-icon { background: url("../img/icons/mobile-nav-menu-icon.png"); width: 25px; height: 20px; border: none; margin-top: 20px; }
  .intro .selection { width: 100%; }
  .intro h1 { margin: 0; font-size: 40px; line-height: 40px; overflow-wrap: break-word; }
  .intro .selection { width: 100%; margin-top: 15px; padding: 30px 0px; }
  .ds-banner div a img { height: 76px; width: 370px; }
  .how-it-works .item, .wrapper .intro .item { margin-bottom: 40px; }
  .how-it-works .item .circle-left, .how-it-works .item .circle-right, .wrapper .intro .item .circle-left, .wrapper .intro .item .circle-right { display: none; }
  .how-it-works h2 { margin-bottom: 30px; font-size: 24px; }
  .how-it-works a { width: 100%; margin-top: 40px; }
  .how-it-works p { margin-top: 10px; font-size: 18px; }
  .how-it-works .icon-box { width: 100px; height: 100px; }
  .how-it-works .icon-box .icon { font-size: 30px; }
  .why-choose .col { margin-bottom: 40px; }
  .why-choose h2 { margin-bottom: 20px; }
  .why-choose img { margin: 10px 0; max-width: 100px; }
  .operation-countries p.note { padding: 0; }
  .footer-menu { text-align: center; margin-bottom: 20px; }
  .social-icons { text-align: center; }
  .copyright { text-align: center; }
  .copyright img { margin-top: 20px; }
  .transfer-summary { padding: 20px; }
  .transfer-summary #checkout-agreements-mobile ol.checkout-agreements { padding-left: 10px; padding-right: 10px; }
  .online-banking-title { line-height: 30px; text-align:center; }
  .wrapper .intro .receiver-info { margin-top: 30px; }
  .wrapper .intro .receiver-info .receiver-fields { padding: 0; }
  .wrapper .intro .fields .select-wrapper { margin: 0; }
  .wrapper .intro .fields input[type="submit"] { margin: 0 auto 20px; }
  .wrapper .intro .payment-methods { padding: 0; }
  .choosing ul li { margin: 10px 0; width: 85px; float: none !important; }
  .center-align-mobile { text-align: center !important; }
  .registration .social-login .facebook-login, .registration .social-login .twitter-login { width: 100%; display: block; float: none; margin: 0 0 10px; }
  .registration-form .radio-buttons { padding: 0 20px; margin-bottom: 0; }
  .registration-form .radio-buttons label { margin-right: 10px; }
  .registration-form .field { margin-bottom: 20px; }
  .registration-form .field:last-child { margin-bottom: 0; }
  .registration-form input[type="email"], .registration-form input[type="text"], .registration-form input[type="password"], .registration-form input[type="email"] { margin: 0; }
  .success-info, .unsuccess-info { text-align: center; }
  .success-info img, .unsuccess-info img { float: none; margin: 0 0 10px; }
  .success-info .invalid, .unsuccess-info .invalid { margin: 0 0 10px; }
  .trustpilot { margin-bottom: 20px; }
  .call-to-action a { margin-top: 20px; }

  .meter-container { margin-top: 0px; }
  .meter-container .meter.large { width: 100%; margin-bottom: 40px; }
  .meter-container .meter.large .eur-left { top: 15px; left: 5px; }
  .meter-container .meter.large .eur-right { right: 5px; top: 15px; }
  .list { margin-bottom: 40px; }
  .steps { padding: 0; margin: 0 auto 20px; }
  .steps li:not(.step-divider) { width: 110px; height: 110px; display: inline-block; margin: 0 12px; line-height: 110px; }
  .product-choice h2 { font-size: 24px; line-height: 30px; }
  .loading-container { float: none; }
  .loader { margin: 0 auto 20px; }
  .loader-text { line-height: initial; }
  .file-field { position: relative; display: inline-block; width: 100%; margin-bottom: 20px; }
  .kyc-upload .file-meta { margin-top: 55px; }
  .kyc-upload .file-meta p.filename { word-break: break-all; }
  .country-slider { height: 220px; }
  .choosen-flag { text-align: center; }
  .verification-image { padding: 30px 0 20px !important; text-align: center; }
  .landing-text .sending .item { text-align: center; }
  .landing-text .sending .item img { float: none !important; margin-bottom: 10px; }
  .landing-text .sending .item h3 { float: none !important; text-align: center; line-height: 36px !important; }
  .pricing-table .package { margin-bottom: 40px; }
  .pricing-table .package:last-child { margin-bottom: 0; }
  .our-video .video-container iframe, .landing-online-banking .table, .landing-online-topup .table { width: 100%; }
  .cubacel-title, .how-it-works-title { line-height: 30px; }
  .promotions span { word-wrap: break-word; }
  .how-works h3 { line-height: 28px; margin: 40px 0 10px; }
  .team .member, .advisors .advisor { text-align: center; margin-bottom: 0px; }
  .team .member .member-image, .team .member .advisor-image, .advisors .advisor .member-image, .advisors .advisor .advisor-image { width: 100%; float: none; margin-bottom: 20px; }
  .team .member .member-details, .team .member .advisor-details, .advisors .advisor .member-details, .advisors .advisor .advisor-details { width: 100%; float: none; padding: 0; }
  .team .member .advisor-details h3, .advisors .advisor .advisor-details h3 { line-height: 24px; }
  .advisors .advisor { margin-bottom: 50px; }
  .intro .landing-text p { margin-bottom: 40px; }
  .intro .landing-text .check input[type="text"] { width: 90%; min-width: 90%; margin-top: 20px; }
  .intro .landing-text .check input[type="submit"] { width: 100%; margin-top: 0px; float:none; }
  #newsletter .validate-email { min-width: 100%; }
  .pt40 { padding-top: 0; }
  .center-image { text-align: center; }
  .send-money h2 { line-height: 36px; }
  .send-money h3 { line-height: 30px; }
  .wrapper .intro.landing { background-size: initial; }
  .wrapper .intro.blue-map-with-background, .wrapper .intro.blue-map { background-position: top center; background-size: initial; }
  .intro .landing-text h1 .icon-with-title { display: block; top: 0px; width: 90px; margin: 0 auto 20px; }
  .intro .landing-text .marketing { padding: 0; }
  .intro .landing-text .marketing input[type="text"] { width: 90%; }
  .intro .landing-text .marketing input[type="submit"] { width: 90%; margin-right: 5%; }
  .image-right { float: none; }
  .intro.home { background: #009eda !important; }
  .youtube-video { padding-left: initial !important; }
  .wrapper .intro .checking input[type="submit"] { display: block; margin: auto; margin-bottom: 20px !important;}
  .registration-form input[type="submit"], .registration-form input[type="button"], .contact-form input[type="submit"], .contact-form input[type="button"] { float: none !important; display: block; margin: auto; }
  .contact .sub-title { margin-bottom: 10px; }
  .selected-country img { margin: 0px; }
  .checkout-onepage-index .testimonials, .checkout-onepage-index .available-products { display: none; }
  .transfer-options h2, .why-choose h2, .testimonials h2, .operation-countries h2 {font-size:25px;line-height:1.4em;}
}
@media screen and (min-width: 320px) and (max-width: 480px) and (orientation: landscape) { .country-slider { height: 135px; } }
@media screen and (min-width: 480px) and (max-width: 768px) { .main-menu li { padding: 0 10px; }
  .how-it-works .item .circle-left, .how-it-works .item .circle-right, .wrapper .intro .item .circle-left, .wrapper .intro .item .circle-right { width: 105px; }
  .how-it-works .item .circle-left, .wrapper .intro .item .circle-left { left: -42px; }
  .transfer-options h3 { line-height: 24px; }
  .social-icons li { margin: 15px 2px 0; width: 34px; }
  .copyright span { line-height: initial; }
  .registration-form .radio-buttons { padding: 0 25px; }
  .wrapper .intro .fields .select-wrapper { margin-top: 27px; }
  .wrapper .intro .fields label { font-size: 15px; }
  .wrapper .intro .fields input[type="submit"] { margin-top: 27px; padding: 0; }
  .unsuccess-info h5, .unsuccess-info p { max-width: 80%; }
  .kyc-upload .btn-large { padding: 0; }
  .youtube-video { padding-left: initial !important; } }
@media screen and (min-width: 768px) and (max-width: 1024px) { .intro .landing-text .sending .item h3 { font-size: 22px; }
  .slick-next { right: -15px; }
  .slick-prev { left: -15px; }
  .youtube-video { padding-left: initial !important; } }
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) { .intro .landing-text .marketing input[type="text"] { width: 50%; }
  .intro .landing-text .online-banking { padding-top: 12px; }
  .intro .landing-text .online-banking input[type="submit"] { width: 80px; }
  .intro .landing-text .check input[type="text"] { margin: 0 20px 0 0; }
  .youtube-video { padding-left: initial !important; } }
.youtube {background-position: center;background-repeat: no-repeat;position: relative;display: inline-block;overflow: hidden;transition: all 200ms ease-out;cursor: pointer;width:100%;height:360px;}
.youtube .play {background: url("../img/icons/play_button.png") no-repeat 50% 45%;background-size: 128px 128px;position: absolute;height: 100%;width: 100%;opacity: .6;filter: alpha(opacity=80);transition: all 0.2s ease-out;}
.youtube .play:hover {opacity: 1;filter: alpha(opacity=100);}
#slide-out{left:inherit !important;right:-250px;}


.moneyInputField
{
  background: white;
  border-radius: 34px;
  /*height:55px;*/
  width:auto;
}

.moneyLeftField
{
  float:left;
  display:block;
  margin-left:-17px;
  height:100%;
  vertical-align: middle;
}

#senderAmount {
  height: 100%;
}

.total-align {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
}

.amount-total-txt-align {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
}

.amount_header_text,
.topup_header_text{
  font-size: 20px;
  text-align:center;
  margin-bottom:10px;
  padding-left:25px;
  color:black;
  font-weight:bold;
}

.moneyLeftField input {
  width: 100% !important;
  margin-right:-82px;
  background-color: transparent;
}

.currencyRightField
{
  display:block;
  border-left: 2px solid #ccc;
  font-weight: bold;
  width:80px;
  float:right;
  line-height:55px;
  padding-right:9px;
  cursor: pointer;
}

.show {display:block;}

.clear {clear: both;}
.no-bottom-margin {margin-bottom:0;}

.banking-transfer-amount .validation-advice { width: 100%; text-align: center; margin: auto; }
.banking-transfer-amount .fields .col.m3 { padding-left: 0; }
.banking-transfer-amount .wrapper .intro .fields input[type="text"] { width: 55%; }
.banking-transfer-amount .wrapper .intro .fields label { margin-left: 0; }
.banking-transfer-amount .wrapper .intro { padding-top: 10px; }
.banking-transfer-amount .row.fields, .cms-page-view .row.field { margin-bottom: 0px !important; padding-bottom: 0px !important; }
/* .cms-page-view .intro .landing-check .old-landing-check:first-child.col  { display: none; } */

@media screen and (max-width: 640px) {
  .cms-page-view .intro .landing-check .old-landing-check.m10 .row { line-height: 25px; margin-bottom: 0px; }
  .cms-page-view .intro .landing-check .old-landing-check.m10 .btn-large-banking { margin-bottom: 10px; margin-top: 0px !important; }
  .cms-page-view .intro .landing-check .old-landing-check.m10 .btn-large-banking { width: 100%; margin-bottom: 20px; }
  .cms-page-view .intro .landing-check .landing-text:last-child img { margin: 15px; }
  .catalog-category-view .intro .main-container aside.row img{ margin-top: 30px; }
 /* .intro { padding: 0px; } */
    .intro {
        padding: 15px 0 0 0;
    }
}



.customer-dob select, .customer-dob option, .nationalities select, .nationalities option
{
  display: block;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 0 4%;
  text-align: left;
  height: 55px;
  outline: none;
  -moz-appearance: none;
}
select::-ms-expand {display: none;}

/* Mobile Language Selector */
#header_mobile_language_selector, #hmls_actual {
  display:none;
}

/* Custom Tooltip */
.man_tooltip {
  position:absolute;
  border-radius:10px;
  padding:30px;
  background-color:#fff;
  margin-left:-83px;
  margin-top:45px;
  z-index:9999;
  width:200px;
  font-family:"Helvetica", sans-serif;
  line-height:1.3em;
  font-size:14px;
  color:#333;
}
/*# sourceMappingURL=style.css.map */
.customer-dob select, .customer-dob option,
.nationalities select, .nationalities option
{
  display: block;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  text-align: left;
  padding: 0 4%;
  height: 55px;
}

ul.step-switcher li .icon {
  margin: auto;
  font-size: 3em !important;
  color: #009eda;
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
ul.step-switcher li.current .icon-box {
  background: #009eda !important;
}
ul.step-switcher li.check .icon-box {
  background: #a4c843 !important;
}
ul.step-switcher li.current .icon, ul.step-switcher li.check .icon  {
  color: white;
}
ul.step-switcher li p {
  text-align: center;
}
ul.step-switcher li.check p {
  color: #a4c843 !important;
}
ul.step-switcher li .info-icon {
  display: none;
}
ul.step-switcher li.current .info-icon {
  display: block;
}
ul.step-switcher li.check .check-icon {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff url("../img/icons/check.png") no-repeat center center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.slick-slide {
  visibility: hidden;
}
.slick-slide.before-prev, .slick-slide.prev, .slick-slide.slick-center, .slick-slide.next, .slick-slide.before-next {
  visibility: visible;
}
ul.slick-slider li {
  cursor: pointer;
}
.nationalities .twitter-typeahead, .nationalities .tt-menu, .countries .twitter-typeahead, .countries .tt-menu  {
  width: 100%;
}


@media screen and (max-width: 640px) {

  #header_mobile_language_selector {
    display: block;
    position: absolute;
    right: 70px;
    padding-top:8px;
  }

  select#hmls_actual {
    display: block !important;
  }

  select#hmls_actual {
    text-transform:uppercase;
  }

}


/* Styled selects */
.s-hidden {
  visibility:hidden;
  padding-right:10px;
}
.select {
  cursor:pointer;
  display:inline-block;
  position:relative;
  font:normal 11px/22px Arial, Sans-Serif;
  color:black;

  display: block;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 0 4%;
  height: 55px;
  text-align: right;
  line-height:55px;
}
.styledSelect {
  position: static;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  padding: 0 20px;
  padding-right: 55px;
  height: 55px;
  text-align: right;
  line-height: 55px;
  font-size: 16px;
  background: url(../img/icons/arrow-down-green-big.png) right 5px center no-repeat white;
  min-width: 110px;
  text-indent: 1px;
  font-weight: normal;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.styledSelect:active, .styledSelect.active {


}

.list-select {
  position: static;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  font-weight: normal;
}

@media screen and (max-width: 320px) {
  .styledSelect {
    left: -0.9em!important;
    text-align: right;
    background: url(../img/icons/arrow-down-green-big.png) right 5px center no-repeat white;
  }
}
.options {
  font-size:16px;
  display:none;
  position:absolute;
  top:100%;
  right:0;
  z-index:999;
  margin:0 0;
  padding:0 0;
  list-style:none;
  border:1px solid #ccc;
  /*border-bottom:5px solid #009DD9;*/
  margin-top:10px;
  margin-bottom:20px;
  background-color:white;
  -webkit-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
  max-height:300px;
  overflow-y:scroll;
  overflow-x:hidden;
}
.options li {
  padding:0 6px;
  margin:0 0;
  padding:0 10px;
  line-height:26px;
}
.options li:hover {
  background-color:#39f;
  color:white;
}

#fat_accordion h3 {
  cursor:pointer;
}

#fat_accordion .bac {
  display:none;
}

/*# sourceMappingURL=style.css.map */

/* IBAN validator */
.iban {
  height: 55px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  width: 100%;
  padding: 0 8%;
  background-color: #ffffff;
}

.select-left{
  text-align: left;
}

.iban-code-country {
  width: 20px;
  height: 56px;
  line-height: 56px;
  position: absolute;
  text-align: right;
  color: gray;
}

.iban-input{
  margin-left: 15px !important;
  width: 88% !important;
}

.block-newsletter{
  padding-bottom: 15px;
}

.btn-newsletter {
  border: 1px solid #ccc !important;
  height: 55px !important;
  background: #a4c843 none !important;
  text-decoration: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  color: #FFF !important;
  text-align: center !important;
  letter-spacing: .5px !important;
  cursor: pointer !important;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  border-radius: 30px !important;
  width: 100% !important;
}

.input-newsletter {
  background: white !important;
  border: 2px solid #a4c843 !important;
  height: 55px !important;
  border-radius: 30px !important;
  padding-left: 10px  !important;
  margin-right: 15px  !important;
}

/* newsletter design for the old template */

.modal {
  background: white !important;
  padding: 25px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  -webkit-box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.25) !important;
  -moz-box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.25) !important;
  box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.25) !important;
  overflow: visible !important;
  width: 24em !important;
  min-height: inherit !important;
  max-height: none !important;
  height: initial !important;
}

.modal .newsletter-modal-title {
  font-weight: 600;
  font-size: 1.64rem;
  line-height: 1.804rem;
  margin: 0.82rem 0 0.656rem 0;
}

.modal .input-newsletter-modal {
  background: white !important;
  border: 2px solid #a4c843 !important;
  height: 55px !important;
  border-radius: 30px !important;
  text-align: center !important;
}

.modal .btn-newsletter-modal {
  border: 1px solid #ccc !important;
  height: 55px !important;
  background: #a4c843 none !important;
  text-decoration: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  color: #FFF !important;
  letter-spacing: .5px !important;
  cursor: pointer !important;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  border-radius: 30px !important;
  width: 100% !important;
  text-align: center !important;
}

.modal .success-msg {
  border: 0px;
  background-color: #ffffff;
  color: #7AAA65 !important;
  font-size: 20px;
  text-align: center;
}

#newsletter-modal .newsletter-modal-title {
  text-align: center !important;
}

.modal .block-title {
  text-align: center !important;
}

.modal .validation-advice {
  margin-bottom: 12px;
  margin-top: 0px;
}

.modal a.close-modal {
}

.reason-select .options, .bank-select .options, .account-type-select .options, .document-type-select .options {
  text-align: left !important;
  padding-right: 0px !important;
  left: -10px;
}

.reason-select select, .bank-select select, .account-type-select select, .document-type-select select{
  text-align: left !important;
  padding-right: 0px !important;
}

.reason-select, .bank-select, .account-type-select, .document-type-select {
  margin-top: -8px !important;
}

.reason-select, .bank-select, .account-type-select label {
  color: #000;
  font-size: 15px;
}

.reason-select .select, .bank-select .select, .account-type-select .select, .document-type-select .select{
  margin-top: 5px !important;
}

.reason-select .styledSelect, .bank-select .styledSelect,  .account-type-select .styledSelect, .document-type-select .styledSelect{
  overflow: hidden;
  padding-right:46px !important;
  text-align: left !important;
  width: 104%;
  left: -10px;
  color: #999999;
  font-weight: 100;
}

#check-number .load-more.load-more--loading {
  -webkit-animation: rotate 1.5s linear infinite !important;
  animation: rotate 1.5s linear infinite !important;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
  background-color: transparent !important;
  border: .3em solid white !important;
  border-radius: 2em !important;
  border-top-color: #a4c843 !important;
  box-sizing: border-box !important;
  height: 55px !important;
  color: transparent !important;
  padding: 0 !important;
  pointer-events: none !important;
  width: 55px !important;
  background-color: transparent !important;
  min-width: 55px !important;
}

#newsletter .validation-advices {
  border: 1px solid #911;
  background-color: #fff6f6;
  padding: 5px 10px;
  border-radius: 5px;
  clear: both;
  float: none;
  color: #911 !important;
  margin: 10px 0;
  font-weight: 400;
}
/* Left - Right blocks, are swapping the position on mobile views*/
@media all and (min-width: 767px) {
  .lr-left {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    box-ordinal-group: 2;
  }

  .lr-right {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    box-ordinal-group: 3;
  }
}
@media all and (max-width: 767px) {
  .lr {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
  }
  .lr-left {
    margin-top: 15px;
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    box-ordinal-group: 3;
  }

  .lr-right {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    box-ordinal-group: 2;
  }
}


/* START NEW TOPUP DESIGN */
/*------------------------------------------------------------*/

.new-topup {
  background: #eeeeee;
  background-image: none !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.new-topup .topup-title {
  color: #009eda;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.topup-number-check .number-check-prefix {
  padding: 0 5% !important;
  background: none !important;
}

.new-topup .warning, .new-topup .warning-blue {
  font-weight: bold;
  text-align: center;
  border-radius: 30px;
  padding: 5px;
  margin-top: 10px;
  display: none;
  width: 100%;
  color: white;
}

.new-topup .warning {
  background-color: #ff3950;
}

.new-topup .warning-blue {
  background-color: #009eda;
}

#check-number .load-more.load-more--loading {
  -webkit-animation: rotate 1.5s linear infinite !important;
  animation: rotate 1.5s linear infinite !important;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
  background-color: transparent !important;
  border: .3em solid white !important;
  border-radius: 2em !important;
  border-top-color: #a4c843 !important;
  box-sizing: border-box !important;
  height: 55px !important;
  color: transparent !important;
  padding: 0 !important;
  pointer-events: none !important;
  width: 55px !important;
  background-color: transparent !important;
  min-width: 55px !important;
}

#newsletter .validation-advices {
  border: 1px solid #911;
  background-color: #fff6f6;
  padding: 5px 10px;
  border-radius: 5px;
  clear: both;
  float: none;
  color: #911 !important;
  margin: 10px 0;
  font-weight: 400;
}


/* new landing page *//* new landing page *//* new landing page *//* new landing page *//* new landing page *//* new landing page *//* new landing page *//* new landing page *//* new landing page */

.new-landing-page .text-seo{
  text-align: justify;
  margin-left: 4em;
  margin-right: 4em;
}

.new-landing-page h1 { color: #009eda; font-size: 36px; margin-top: 20px; line-height: 35px; }
.new-landing-page .padding{ padding-left: 0px !important; }

/* new topup */

#pricing p{
  text-align: center !important;
}

#pricing{
  width: 100%;
  margin: auto;
  text-align: center !important;
}

#pricing .price_card{
  width: 15em;
  background: rgb(255, 255, 255);
  display: block;
  float: left;
  top: 0;
  border-radius: 25px;
}
#pricing .price_card:not(:last-child){
  margin-right: 15px;
}
#pricing .price_card_header{
  color: rgb(255, 255, 255);
  border-radius: 25px 25px 0 0;
}

#pricing .alpha .price_card_header{
  background: #009EDA;
}
#pricing .bravo .price_card_header{
  background: rgb(246, 77, 77);
}
#pricing .charlie .price_card_header{
  background: rgb(48, 219, 181);
}
#pricing .price{
  width: 100%;
  font-size: 60px;
  display: block;
  text-align: center;
  padding: 20px 0 10px;
}
#pricing .name{
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  display: block;
  text-align: center;
  padding: 0 0 30px;
}
#pricing .features{
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
}
#pricing .features li{
  margin: 0px;
  padding: 20px 15px;
  width: 100%;
}
#pricing .features li:not(:last-child){
  border: 1px solid rgb(242, 242, 242);
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
#pricing button{
  color: rgb(255, 255, 255);
  border: 0;
  border-radius: 5px;
  height: 40px;
  width: 200px;
  display: block;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  margin: 20px auto 35px;
}
#pricing .alpha button{
  background: #009EDA;
}

#pricing .tip{
  font-family: 'Indie Flower';
  position: absolute;
  color: rgb(117, 117, 117);
  margin: 10px 5px;
}

.step-switcher {
  display: none !important;
}


/*--------------------
    .pc-price-5
-------------------*/

@media all and (min-width:0px) {
  .row-mobile { width: 100% !important; margin: 0px; }
  .topup-number-check { margin: 0px !important;}
}

#new-top-up-prices .pc-btn-5 {
  background-color: #f9f9f9;
  /* border: 1px solid #ccc; */
  border-radius: 0 0 20px 20px;
  color: #bbb;
  display: block;
  font-weight: 700;
  line-height: 50px;
  padding: 0 45px;
  text-transform: uppercase;
  width: 13.41rem;
  margin-left: -1.3em;
  margin-top: 1em;
  margin-bottom: -2em;
}
#new-top-up-prices .pc-price-5 {
  background-color: #fff;
  color: #6f6f6f;
  padding: 20px;
  border-radius: 25px;
  text-align: center;
  width: 13.4rem;
  /*min-height: 258px;*/
  margin-bottom: 15px;
}

#new-top-up-prices .pc-price-5 h3 {
  font-family: "Dosis", sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #777777;
  margin-bottom: 7px;
}
#new-top-up-prices .pc-price-5 .sep {
  background-color: #dcdfe3;
  height: 3px;
  margin-top: 10px;
}
#new-top-up-prices .pc-price-5 .sep span {
  background-color: #a0a0a0;
  display: block;
  height: 3px;
  margin: 0 auto;
  width: 68px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#new-top-up-prices .pc-price-5 span {
  font-size: 16px;
}
#new-top-up-prices .pc-price-5 .pricing {
  font-family: "Dosis", sans-serif;
  margin: 0;
  position: relative;
  padding-top: 10px;
}
#new-top-up-prices .pc-price-5 .pricing .currency {
  font-size: 22px;
  font-weight: 400;
  margin-right: 1px;
}
#new-top-up-prices .pc-price-5 .pricing .value {
  font-size: 55px;
  font-weight: bold;
  line-height: 100%;
}
#new-top-up-prices .pc-price-5 .price-body {
  margin-top: 20px;
}
#new-top-up-prices .pc-price-5 .pc-btn {
  display: none;
}
#new-top-up-prices .pc-price-5 .pricing .currency {
  font-size: 22px;
  font-weight: 400;
  margin-right: 1px;
}
#new-top-up-prices .pc-price .price-body .row{
  margin: 0px;
  padding: 0px;
}
#new-top-up-prices .price-body .row {
  margin-left: 0;
  margin-right: 0;
}

#new-top-up-prices .price-body .row .col {
  padding-left: 0;
  padding-right: 0;
}

#new-top-up-prices .bonus {
  color: red !important;
  font-weight: bold;
}

#new-top-up-prices .top-up-selection{
  width: 100%;
  margin: auto;
  padding-bottom: 50px;
  text-align: center;
}

#new-top-up-prices .itens-list .item-col {
  width: 10em;
  margin-left: 1em;
  display: inline-table;
  min-width: 169px;
  cursor:pointer;
}

#new-top-up-prices .itens-list .item-col:first-child{
  margin-left: 0px;
}

#new-top-up-prices {
  text-align: center;
}

#new-top-up-prices .intro {
  padding: 10px 0;
}

.topup-number-check .number-check-prefix {
  width: 45px !important;
  padding-right: 5px !important;
  padding-left: 25px !important;
  border-right: 1px solid #d0d0d0 !important;
  border-radius: 0px !important;
  text-align: center;
}

.top-up-selection {
  width: 100%;
  margin: auto;
  padding-bottom: 25px;
  text-align: center;
}

#new-top-up-prices .pricing-active-color {
  color: #009eda;
}

#new-top-up-prices .pc-btn-active-color {
  background-color: #009eda;
  color: white !important;
}

#new-top-up-prices .pc-price-5:hover .sep span,
#new-top-up-prices .active .sep span {
  background-color: #009eda;
}

#new-top-up-prices .pc-price-5:hover .sep span,
#new-top-up-prices .active .sep span {
  background-color: #009eda;
}
#new-top-up-prices .pc-price-5:hover .pc-btn,
#new-top-up-prices .active .pc-btn {
  background-color: #009eda;
  border-color: #009eda;
  color: #fff;
}

#new-top-up-prices .item-col .sep .sep-active {
  display: block;
}

@media screen and (max-height: 550px) {
  .step-list .choosen-flag img {
    display: none;
  }
}

@media only screen and (min-width: 601px){
  .new-topup .row .col.m6 {
    width: 55%;
    margin-left: 0;
  }
}

.new-topup .fake-input .number-check {
  background: none;
  border: none;
  height: 55px;
  width: 15em !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: none !important;
  box-shadow: none !important;
  text-indent: 10px;
}

.new-topup .fake-input .flags {
  background-color: white;
  position: absolute;
  margin-top: 15px;
  margin-left: 20px;
  max-width: 35px;
}

.new-topup .fake-input .number-check-btn {
  margin-left: -50px;
  float: right;
  /* position: absolute; */
  height: 55px;
  background-color: #009eda;
  color: white;
  font-weight: bold;
  border: 0;
  border-radius: 0 30px 30px 0;
  width: 30%;
}

.new-topup .fake-input {
  background-color: white;
  height:55px;
  border-radius: 30px;
}

.new-topup .progress {
  background-color: #ffffff;
  height:5px;
  display: block;
  width: 90%;
  margin: auto;
  margin-top: 10px;
}

.new-topup .progress .indeterminate {
  background-color: #009eda;
}

#new-top-up-prices .price-body-right, #new-top-up-prices .price-body-right-total {
  text-align: right;
  color: #000;
  font-weight: bold;
}

#new-top-up-prices .price-body-left, #new-top-up-prices .price-body-left-total {
  text-align: left;
  color: #000;
}

#new-top-up-prices .currency-symbol {
  display: inline;
  color: #a5a5a5;
}

#new-top-up-prices .item-col .sep span {
  float: left;
  border-right: 3px solid white;
  background-color: #d0d0d0 !important;
  display: block !important;
}
#new-top-up-prices .item-col .sep span:last-child {
  border-right: 0px;
}
#new-top-up-prices .item-col .sep .sep-active {
  background-color: #3498db !important;
}

/* Testimonials */
.new-landing-page .testimonials { padding: 0px; background-color: white; }
.intro.no-pad {}
.intro {}

.new-landing-page .padding { padding-left: 4.5em !important; }

@media all and (max-device-width:319px) {
  #new-top-up-prices .itens-list .item-col{ width: 100% !important; }
  .new-topup .topup-number-check { margin: 20px !important; }
}

@media only screen and (min-width : 0px) and (max-width : 320px) {
  #new-top-up-prices .itens-list .item-col { width: 80%; margin-left: 0px; }
  #new-top-up-prices .pc-price-5 { width: 100% !important; border-radius:0px; }
}

@media all and (max-device-width:320px) {

  .new-topup .topup-number-check {
    margin: 0px !important;
  }

  .row-mobile {
    margin: auto;
  }

  .new-topup {
    padding-top: 20px;
  }

  .new-topup .fake-input {
    background-color: white;
    height: 55px;
    border-radius: 30px;
    width: 100%;
    margin-left: 0px;
  }

  .new-topup .fake-input .number-check { width: 73% !important; }
  .new-topup .fake-input .number-check-prefix { margin-bottom: 5px !important; }
  .new-topup .fake-input .number-check-btn { width: 100%; }

  .new-topup .progress { float: left; }

  ::-webkit-input-placeholder { /* WebKit browsers */
    display: block;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    display: block;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    display: block;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    display: block;
  }

}

@media screen and (min-width: 640px){
  .row-mobile { width: 50% !important; margin-bottom: 20px !important; }
  .new-topup .fake-input .number-check { width: 15em !important; }
  .topup-number-check .number-check-prefix-margin { margin-left: 35px; }
}

/*
@media screen
and (min-device-width: 1200px)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 1) {
}*/

.row-mobile {
  width: 60%;
  margin: auto;
}

@media only screen and (max-device-width: 480px) {

  .new-topup .fake-input{
    border-radius: 0px;
  }

  .new-topup .fake-input .number-check-btn{
    border-radius: 0px;
    width: 100%;
  }

  #new-top-up-prices .pc-price-5 {
    border-radius: 0px;
  }

  .row-mobile {
    width: 100%;
  }

  .new-landing-page .text-seo{
    text-align: justify;
    margin-left: 1em;
    margin-right: 1em;
  }

  .new-landing-page .container {
    padding: 1em !important;
    overflow: hidden;
  }

  .new-landing-page .padding {
    padding-left: 0px !important;
  }

  #new-top-up-prices .pc-btn-5 {
    width: auto;
    margin-left: -1.4em;
    margin-right: -1.45em;
    margin-top: 1em;
    margin-bottom: -2em;
    position: initial;
  }

  .new-topup .fake-input .flags {
    display: none;
  }

  .topup-number-check .number-check-prefix {
    margin-left: 0;
    padding-left: 10px !important;
  }

  #new-top-up-prices .pc-price-5 .pricing .value
  {
    font-size: 75px;
  }

  #new-top-up-prices .pc-price-5 .pricing .currency {
    font-size: 30px;
  }

  .new-topup .fake-input .number-check {
    position: absolute !important;
    margin-right: 0px !important;
    border-bottom: none !important;
    box-shadow: none !important;
  }

  #new-top-up-prices .price-body-left, #new-top-up-prices .price-body-left-total {
    text-align: left;
  }

  .new-topup .banking-title, .topup-title {
    text-align: center;
  }

  #new-top-up-prices .slider {
    width: 50%;
    margin: 100px auto;
  }

  #new-top-up-prices .slick-slide {
    visibility: visible;
    padding: 0 1em 10px 1em;
  }

  #new-top-up-prices .slick-slide img {
    width: 100%;
  }

  #new-top-up-prices .slick-prev:before,
  #new-top-up-prices .slick-next:before {
    color: black;
  }
  #new-top-up-prices .slick-slider {
    width: 100% !important;
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  #new-top-up-prices {
    text-align: center;
    width: 100%;
    margin-left: 0;
    margin-bottom: 0px;
  }
  #new-top-up-prices .step-list {
    margin-top: 0px;
  }
  #new-top-up-prices .wrapper .intro .checking input[type="submit"] {
    display: block;
    width: 100%;
    margin: auto;
  }
  #new-top-up-prices .slick-prev, #new-top-up-prices .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 20%;
    display: block !important;
    width: 20px;
    height: 200px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    margin-top: -10%;
  }
  #new-top-up-prices .slick-prev {
    left: -15px;
  }
  #new-top-up-prices .slick-next {
    right: -15px;
  }

  #new-top-up-prices .slick-prev:before, #new-top-up-prices .slick-next:before { font-family: "slick"; font-size: 70px; line-height: 1; color: #009eda; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

  #new-top-up-prices .slick-prev:before { content: "‹"; }
  [dir="rtl"] #new-top-up-prices .slick-prev:before { content: "›"; }

  [dir="rtl"] #new-top-up-prices .slick-next { left: -10px; top: 70px; right: auto; }
  #new-top-up-prices .slick-next:before { content: "›"; }
  [dir="rtl"] #new-top-up-prices .slick-next:before { content: "‹"; }
  #new-top-up-prices .slick-slider {
    margin-bottom: 0px;
  }
  #new-top-up-prices .itens-list .item-col {
    margin-left: 0;
    margin-right: 0;
    min-width: 21em;
  }
  #new-top-up-prices .pc-price-5 {
    width: 100%;
    height: 100%;
  }

  #new-top-up-prices .currency-symbol {
    font-weight: bolder;
  }

}

@media screen and (max-width: 768px){
  .new-topup .fake-input .number-check { width: 12em !important; }
  .new-topup .warning, .new-topup .warning-blue { border-radius: 0px; float: left;}
}

@media screen and (max-width: 480px){
  .new-topup .step-list { margin-top: 0px !important; }
  .topup-number-check { margin: 20px !important }
  .new-topup .recharge { border-radius: 0px !important; }
  .new-topup .fake-input .number-check { width: 49% !important; }
  .new-topup .fake-input .nauta { width: 100% !important;  position: inherit !important; margin-bottom: 10px !important; }
}

@media screen and (max-device-width:360px) {
  .new-topup .topup-number-check { margin: 20px !important; }
}

@media screen and (max-width: 320px){
  .new-topup .step-list .valign { margin-top: 0px !important; }
  .new-topup .flags { display: none; }
  .new-topup .step-list { margin-top: 0px !important; }
  .new-topup { padding-top: 10px !important; }
  #new-top-up-prices .pc-btn-5 { border-radius: 0px !important; }
  #product_addtocart_form { margin-top: 30px; }
  .topup-number-check { margin-top: 20px !important;}

  .new-topup .fake-input .number-check { border-radius: 0px; }
  .new-topup .fake-input { border-radius: 0px; }
  .new-topup .fake-input .number-check-btn { width: 100%; border-radius: 0px; float: left; margin-left: 0px; }
}


@media only screen and (max-width: 320px){
  .topup-number-check .number-check-prefix { width: 45px !important; padding-right: 5px !important; padding-left: 5px !important; border-right: 1px solid #d0d0d0 !important; border-radius: 0px !important; margin-bottom: 0px !important; }
  #new-top-up-prices .pc-btn-5 { background-color: #009eda; border-radius: 0 0 20px 20px; color: #fff; display: block; font-weight: 700; line-height: 50px; padding: 0 45px; text-transform: uppercase; width: 121% !important; margin-left: -1.4em; margin-top: 2em; margin-bottom: -2em; }
  .new-topup .fake-input .number-check { margin-bottom: 10px !important; border-radius: 0px; height: 50px !important; }
  .new-topup .progress { background-color: #ffffff; height: 5px; display: block; width: 90%; margin: auto; margin-top: 10px;float: left; }
}

@media only screen and (max-device-width: 320px){
  #new-top-up-prices .pc-btn-5 { width: auto !important; }
}

@media screen and (min-aspect-ratio: 13/9 ) {
  .row-mobile { width: 30em !important; margin-bottom: 20px !important; }
  .new-topup { margin-top: 0px; padding-top: 0px; }
  .new-topup .step-list { margin-top: 0px; }
}

.fake-input-text-mobile { display: block; text-align: center; margin: auto; margin-bottom: 10px; }

#button-continue:disabled, #button-continue.disabled, input#precheck:disabled, #recharge:disabled {
  background-color: grey;
  cursor: not-allowed;
  pointer-events: none;
}

#button-continue.forceActive {
  background-color: #a4c843;
  cursor: pointer !important;
  color: white;
  pointer-events: auto;
}

#button-continue.forceActive:hover {
  background: #8daf33 none;
}

.new-seo-page {
  background-color: #eee;
}

.new-seo-page .container{
  background-color: #eeeeee;
  margin: auto;
}

.internal_links_columns{
  float: left;
  width: 33%;
  overflow: hidden;
}

.internal_links_columns h5 {
  text-transform: capitalize;
}

@media screen and (max-width: 640px) {
  .internal_links_columns {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .new-seo-page h1{
    padding-top: 5%;
  }
  .new-seo-page .valign-wrapper {
    display: block !important;
  }
  .new-seo-page .rounded,
  .internal-links-module {
    width: 100%;
    background-color: white;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 0px;
    text-align: left;
    float: left;
    word-break: normal;
    max-width: 467px;
  }
  .lp-links .sections {
    text-align: left;
    float: left;
    width: auto;
    margin-left: 10px;
  }

  .new-seo-page .number-prefix .number-check-prefix {
    width: 25%;
    height: 55px;
  }

  .new-seo-page .number-prefix .number-field {
    width: 75%;
    height: 55px;
    border: 1px solid #ccc;
  }

  .new-seo-page .number-check-btn {
    width: 100%;
    height: 55px;
    border: 1px solid #ccc;
    text-align: center;
    background-color: #009eda;
    color: white;
  }
}


@media screen and (min-width: 640px){

  .new-seo-page .rounded, .new-seo-page .full-row {
    margin: auto;
    margin-bottom: 1%;
    margin-top: 1%;
  }

  .new-seo-page .rounded,
  .internal-links-module {
    width: 100%;
    margin: 10px;
    padding: 2em;
    margin-left: 1%;
    background-color: white;
    border-radius: 15px;
    float: left;
    text-align: justify;
  }

  .new-seo-page .rounded:first-child {
    /* margin-left: 0; */
  }

  .new-seo-page .number-field {
    float: left;
    width: 100%;
    border: 1px solid #ccc;
    height: 55px;
  }

  .new-seo-page .number-check-btn {
    margin-bottom: 0px;
    background-color: #009eda;
    border: 0px; color: white;
    height: 55px;
  }
}

.new-seo-page .number-prefix .number-check-prefix {
  border: 1px solid #ccc;
  text-align: center;
}

.new-seo-page .cms-price-table {
  width:100%;
  text-align: center;
  margin: auto;
  border: 1px solid #ccc;
  margin-top: 15px;
  float: left;
}
.new-seo-page .cms-price-table th{
  border: 1px solid #2d78bd;
  text-align: center;
  background-color: #009eda;
  color: white;
  border-radius: 0px;
}

.new-seo-page .cms-price-table td{
  border: 1px solid #ccc;
  text-align: center;
}

.new-seo-page .cms-price-table .prices_table_receive {
  background-color: #2d78bd;
}

.new-seo-page .cms-price-table .prices_table_payment_options {
  background-color: #18a9e0;
}

.new-seo-page .lp-links {
  margin: auto;
}

.new-seo-page .lp-links p {
  margin: 0;
  text-align: left;
}

.new-seo-page .cms-call-to{
  background-color: #a4c843;
}
.new-seo-page .cms-call-to:hover{
  background-color: #8fb42b;
  cursor: pointer;
}

.new-seo-page h1 {
    font-size: 2em;
}

.new-seo-page h2 {
    font-size: 1.4em;
    line-height: 2rem;
}

.new-seo-page .cms-call-to-text{
  color: white;
  font-size: 20px;
  text-align: center;
}

.new-seo-page .cms-social-facebook{
  background-color: #2d78bd;
}
.new-seo-page .cms-social-facebook-text{
  color: white;
  font-size: 20px;
  text-align: center;
}
.new-seo-page .cms-social-facebook-logo {
  background: url("../img/fb-reg.png") no-repeat center right !important;
}
.new-seo-page .prices{
  margin-left: auto;
  /*margin-right: auto;*/
  max-width: 467px;
}

.step-one-col {
  text-align: center;
  position:relative;
}

.special-message {
    background-color: #fff;
    border-radius: 20px;
    text-align: start;
    padding: 30px !important;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.cms-page-view .step-one-col {
    width: 100% !important;
}

.cms-page-view .intro.landing{
  padding: 0px;
}

.wrapper .intro .checking input[type="submit"] { display: block; margin: auto; }

@media screen and (min-width: 640px) {
  .step-one-col {
    float: right !important;
    margin-right: 20px;
  }
}
@media screen and (max-width: 640px) {
  .step-one-col {
    float: none;
    margin-right: 0px;
  }
}
.btn-large-banking {
  width: 50%;
  padding: inherit !important;
  height: 55px;
}

.landing-check .btn-large-banking {
  margin-top: 20px !important;
}

/*------------------------------------------ FIX BLOG ----*/
@media (max-width:600px) {

  .is-blog .page-gray{
    background: #009eda;
    padding: 0;
  }

  .is-blog .blog{
    background-color: #009eda;
    padding: 0px;
    margin-top: 0px;
  }

  .is-blog .content{
    padding: 0;
    border: 0px;
  }

  .is-blog .blog-title p{
    display: block;
    font-size: 13px;
    text-align: left;
    color: #000;
  }

  .is-blog .post-title a h1 {
    font-size: 25px;
    line-height: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: left;
  }

  .is-blog .sub-title {
    font-size: 18px;
    text-transform: initial;
    display: block;
    line-height: normal;
    font-weight: 300;
  }

  .is-blog .blog-title {
    padding: 20px;
    color: white;
  }

  .is-blog h1 {
    margin: 0px;
    font-size: 25px;
    line-height: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .is-blog h2{
    font-size: large;
    line-height: inherit;
    margin: 10px 0px;
  }

  .is-blog h3 {
    font-size: x-large;
    line-height: inherit;
    margin: 10px 0px;
    color: black;
  }

  .is-blog .amount{
    text-align: center;
  }

  .is-blog .pages {
    background-color: white;
    padding: 20px;
    border-radius: 0 0 2px 2px;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    margin-bottom: 25px;
  }

  .is-blog .pages li, .is-blog .pages ol {
    display: inline;
    margin-left: 4%;
  }

  .is-blog img.alignleft, .is-blog img.alignright, .is-blog img.aligncenter {
    margin: 1.625em 0px !important;
    width: 100% !important;
  }

  .is-blog .blog p {
    margin-bottom: 15px;
  }


  .is-blog .post-excerpt {
    text-align: left !important;
    font-size: 16px;
    display: inline-block;
    width: 100%;
    color: #7f7f7f;
  }

  .is-blog.post-excerpt hr {
    display: none;
  }

  .is-blog .blog .content .post {
    margin-bottom: 15px;
  }

  .is-blog .sidebar, .is-blog .single-post{
    background-color: white;
    padding: 20px;
    border-radius: 0 0 2px 2px;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    margin-bottom: 25px;
  }

  .is-blog .blog .content .post .post-excerpt p {
    text-align: left !important;
  }

  .is-blog .single-post{
    margin-bottom: 50px;
  }

  .is-blog .blog .row .col {
    padding: 0;
  }

  .wordpress-post-view .blog-title{
    display: none;
  }

  .img[class*="align"],  img[class*="wp-image-"],  .gallery .gallery-icon img {
    padding: 0px;
  }
}

.fca-approved {
  background: url('../img/icons/fca-safe.png') no-repeat 10px center #4a6aa0;
  border-radius: 10px;
  color: white;
  padding: 1px;
  background-size:32px 32px;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 20px;}

.fca-approved a{
  color: #09B2EF;
  font-weight:normal;
  text-decoration: underline;
}

.fca-approved a:hover{
  color: #6cbbef;
}

.fca-approved-topup {
  width: 248px !important;
  padding: 7px;
  line-height: 14px;
  padding-left: 49px;
}

@media (max-width:600px) {
  .fca-approved {
    margin-top: 20px;
  }
}

.clear
{
  clear:both;
}



/*---------------------------------------------------------------------------------------------------------------------- TABS -------------------- */

.trans-tabs-list li {
  cursor: pointer;
}

.trans-accordion {
  display: none;
}

.trans-tabs-container {
  width: 100%;
}

.trans-tab-content {
  display: none !important;
  height: 100%;
}

.trans-tab-content-active {
  display: block !important;
}

@media (min-width: 768px) {
  .transfer-tabs-default {
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    margin-bottom: 3em;
  }
}

.transfer-tabs-default .trans-tabs-list {
  min-width: 300px;
  word-spacing: -4px;
  display: none;
}

@media (min-width: 768px) {
  .transfer-tabs-default .trans-tabs-list {
    min-width: 250px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .transfer-tabs-default .trans-tabs-list {
    min-width: 300px;
  }
}

.transfer-tabs-default .trans-accordion, .transfer-tabs-default .trans-tabs-list > li {
  position: relative;
  display: inline-block;
  word-spacing: normal;
  padding: 5px 20px;
  color: #fff;
  background: #039ad9;
  /*border: 1px solid #b1b1b1;*/
  border-right-width: 0;
  font-size: 16px;
  line-height: 1.1;
  height: 70px;
  vertical-align: middle;
  text-align: left;
  cursor: pointer;
  transition: .3s all ease;
  z-index: 1;
  border-radius: 10px;
}

.transfer-tabs-default .trans-accordion:before, .transfer-tabs-default .trans-tabs-list > li:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}

.transfer-tabs-default .trans-accordion span, .transfer-tabs-default .trans-tabs-list > li span {
  display: inline-block;
  vertical-align: middle;
}

.transfer-tabs-default .trans-accordion:hover, .transfer-tabs-default .trans-accordion.trans-tab-active, .transfer-tabs-default .trans-tabs-list > li:hover, .transfer-tabs-default .trans-tabs-list > li.trans-tab-active {
  color: #000;
  background: #fff;
  /*border: 1px solid #b1b1b1;*/
  border-right: 0px;
}

.transfer-tabs-default .trans-accordion.trans-tab-active, .transfer-tabs-default .trans-tabs-list > li.trans-tab-active {
  cursor: default;
}

@media (min-width: 768px) {
  .transfer-tabs-default .trans-accordion, .transfer-tabs-default .trans-tabs-list > li {
    padding: 5px 16px;
    width: 245px;
  }
  .transfer-tabs-default .trans-accordion:hover, .transfer-tabs-default .trans-accordion.trans-tab-active, .transfer-tabs-default .trans-tabs-list > li:hover, .transfer-tabs-default .trans-tabs-list > li.trans-tab-active {
    width: 253px;
  }
  .transfer-tabs-default .trans-accordion + li, .transfer-tabs-default .trans-tabs-list > li + li {
    margin-top: 10px;
    border-radius: 10px;
  }
}

@media (min-width: 992px) {
  .transfer-tabs-default .trans-accordion, .transfer-tabs-default .trans-tabs-list > li {
    width: 290px;
    font-size: 20px;
  }
  .transfer-tabs-default .trans-accordion:hover, .transfer-tabs-default .trans-accordion.trans-tab-active, .transfer-tabs-default .trans-tabs-list > li:hover, .transfer-tabs-default .trans-tabs-list > li.trans-tab-active {
    width: 305px;
    border-radius: 10px 0px 0px 10px;
    text-align: center;
  }
}

.transfer-tabs-default .trans-accordion {
  display: block;
  border-radius: 0;
  border-right-width: 3px;
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
}

.transfer-tabs-default .trans-tab-content {
  display: none;
  padding: 30px 15px;
  background: #1db8e6;
  /*border: 1px solid #b1b1b1;*/
  background: #fff;
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
  color: #6e7273;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .transfer-tabs-default .trans-tab-content {
    margin-top: 0;
    padding: 42px 38px 20px;
    font-size: 18px;
    text-align: left;
  }
}
@media (max-width: 768px) {
  .transfer-tabs-default .trans-tab-content {
    border-radius: 0px;
  }
}

.transfer-tabs-default .trans-tab-content .transfer-tabs-heading {
  font-size: 30px;
  color: #303334;
  line-height: 28px;
  text-align: center;
  padding-bottom: 20px;
}

.transfer-tabs-default .trans-tab-content .transfer-tabs-heading + p {
  margin-top: 25px;
}

.transfer-tabs-default .trans-tab-content p + p {
  margin-top: -4px;
}

.transfer-tabs-variant-2 {
  overflow: hidden;
}

.transfer-tabs-variant-2 .trans-tabs-list {
  display: none;
}

.transfer-tabs-variant-2 .trans-tabs-list > li {
  position: relative;
  display: inline-block;
}

.transfer-tabs-variant-2 .trans-tabs-list > li.trans-tab-active:before, .transfer-tabs-variant-2 .trans-tabs-list > li.trans-tab-active:after {
  display: block;
}

.transfer-tabs-variant-2 .trans-tabs-list > li.trans-tab-active > .btn {
  border-bottom-color: transparent;
}

.transfer-tabs-variant-2 .trans-tabs-list > li.trans-tab-active > .btn:hover {
  color: inherit;
  background: transparent;
}

.transfer-tabs-variant-2 .trans-tabs-list > li > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: none;
}

.transfer-tabs-variant-2 .trans-tabs-list > li > .btn:hover {
  transition: .3s all ease;
}

.transfer-tabs-variant-2 .trans-tabs-list > li:before, .transfer-tabs-variant-2 .trans-tabs-list > li:after {
  content: '';
  display: none;
  position: absolute;
  width: 5000px;
  border-bottom: 2px solid;
  bottom: 0;
}

.transfer-tabs-variant-2 .trans-tabs-list > li:before {
  right: 100%;
  margin-right: -2px;
}

.transfer-tabs-variant-2 .trans-tabs-list > li:after {
  left: 100%;
  margin-left: -2px;
}

.transfer-tabs-variant-2 .trans-accordion {
  display: block;
}

.transfer-tabs-variant-2 .trans-accordion > .btn {
  display: block;
}

.transfer-tabs-variant-2 .trans-tab-content {
  display: none;
  padding-top: 50px;
}

.transfer-tabs-variant-2 .trans-tab-content + .trans-accordion {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .transfer-tabs:not(.trans-easy-accordion) .trans-accordion {
    display: none;
  }
  .transfer-tabs:not(.trans-easy-accordion) .trans-tabs-list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0px;
  }
}

.trans-tabs-list li.trans-tab-item {
  border: 0px;
  text-align: center;
}

.trans-tabs-list li.trans-tab-active {
  /*border: 1px solid #b1b1b1;*/
  border-right: 0px;
}


.transfer-tabs .iban, .transfer-tabs input[type="text"] {
  background-color: #eeeeee;
  border-radius: 30px;
  padding: 0 8%;
  width: 90%;
}

.transfer-tabs .iban-input {
  margin-left: 50px !important;
}

.transfer-tabs .moneyInputField {
  background: #eeeeee;
}

.transfer-tabs .styledSelect {
  background-color: #eeeeee;
}



.transfer-tabs .card{
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@media screen and (min-width: 640px) {
  .transfer-tabs  .step-one-col {
    margin-right: 0px;
  }
}

@media screen and (max-width: 640px) {
  .transfer-tabs .iban, .transfer-tabs input[type="text"] {
    width: 80%;
  }
}





/*---------------------------------------------------------------------------------------------------------------------- END TABS -------------------- */







/*************** Modal window ***************/

.modalf-open {
  overflow: hidden;
}

.modalf {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modalf.fade .modalf-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modalf.in .modalf-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modalf-open .modalf {
  overflow-x: hidden;
  overflow-y: auto;
}

.modalf-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modalf-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
}


.modalf-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modalf-header:before, .modalf-header:after {
  content: " ";
  display: table;
}

.modalf-header:after {
  clear: both;
}

.modalf-header .close {
  margin-top: -2px;
}

.modalf-title {
  margin: 0;
  line-height: 1.42857;
}

.modalf-body {
  position: relative;
  padding: 15px;
}

.modalf-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modalf-footer:before, .modalf-footer:after {
  content: " ";
  display: table;
}

.modalf-footer:after {
  clear: both;
}

.modalf-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.modalf-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modalf-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modalf-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modalf-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modalf-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modalf-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modalf-lg {
    width: 900px;
  }
}

.modalf {
  display:table !important;
  height: 100%;
  width: 100%;
  pointer-events:none; /* This makes sure that we can still click outside of the modal to close it */
}
.modalf .modal-dialog {
  /* To center vertically */
  display: table-cell;
  vertical-align: middle;
  pointer-events:none;
}
.modalf .modal-content {
  /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
  width:inherit;
  height:inherit;
  /* To center horizontally */
  margin: 0 auto;
}

/* Prevents scrolling behind the modal*/
body.modal-open
{
  overflow: hidden;
  position: fixed;
}

/*----------------------------------------------------*/

.displaynone
{
  display:none;
}


.registration-wasntme-section
{

}

.wasntme-registration-wasntme-btn
{

}

/*------------------------------------------------------------- CART ---------------*/

.mobile-cart {
  text-align: center;
  margin-left: 0;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #ececec;
  border-bottom: 1px solid #ccc;
}

.mobile-cart a {
  color: #009eda;
  font-weight: bold;
}

.mobile-cart a img{
  margin-right: 10px;
  /* height: 16px; */
  width: 35px;
  margin-bottom: -7px;
}

.main-menu .cart a {
  color: white;
}

.main-menu li.cart {
  background-color: #009eda;
  color: white;
  text-align: center;
  cursor: pointer;
}

.main-menu li.cart:hover {
  color: #fff;
  background-color: #0a89b9;
}

.main-menu li.cart:hover a {
  color: #fff;
}


.main-menu .cart a span.badge {
  position: inherit;
  background-color: #ffffff;
  color: #009eda;
  border-radius: 15px;
  margin-left: 10px;
  padding: 2px 5px;
}

.main-menu .cart a span.badge:hover {
  position: inherit;
  background-color: #009eda;
  color: white;
  border-radius: 15px;
  margin-left: 10px;
  padding: 2px 5px;
}

.mobile-cart a span.badge {
  position: inherit;
  background-color: #009eda;
  color: white;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 15px;
}

.main-menu .cart .cart-left {
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}

.main-menu .cart .cart-right {
  float: left;
}

/*------------------------------------------------------------- END CART ---------------*/

/*------------------------------------------------------------- START AMOUNT ---------------*/

.prices h4{
  margin-left: 0px;
  padding: 0;
  margin-top: 0;
}

.new-amount {
  padding: 0px !important;
}

.new-amount-small, .new-amount-mini {
  padding: 0 0 0 0 !important;
  width:100%;
}

@media screen and (max-width: 800px) {
  #cms-vacation-net-developer {
    width: 70%!important;
  }
}
@media screen and (max-width: 500px) {
  #cms-vacation-net-developer {
    width: 100%!important;
  }
}

.new-amount-mini h4,
.new-amount-small h4,
.prices h4,
.new-amount h4{
  text-align: left;
  margin-bottom: 10px;
  font-size: 25px;
  color: #18a9e0;
}


.new-amount-mini .currencyRightField,
.new-amount-small .currencyRightField,
.prices .currencyRightField,
.new-amount .currencyRightField{
  margin-top: -1px;
  cursor: pointer;
}



.new-amount .row .col label,
.new-amount-mini .row .col label,
.new-amount-small .row .col label {
  text-align: left;
}

.new-amount-small .transfer-summary {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 10px !important;
}

.new-amount .row, .new-amount-mini .row, .new-amount-small .row {
  padding: 0px;
  margin: 0px;
}

.new-amount .row .col, .new-amount-mini .row .col, .new-amount-small .row .col {
  padding: 0;
}

.new-amount h4,
.new-amount-mini h4,
.new-amount-small h4{
  margin-left: 0px;
  padding: 0;
  margin-top: 0;
}

.new-amount-right h4,
.new-amount-mini-right h4,
.new-amount-small-right h4{
  color: white;
}

.new-amount .fca-approved,
.new-amount-mini .fca-approved,
.new-amount-small .fca-approved{
  margin-bottom: 0;
  margin-top: 19px;
}

.new-amount .transfer-summary,
.new-amount-mini .transfer-summary,
.new-amount-small .transfer-summary {
  padding: 20px;
  border-radius: 10px;
  margin-top: 18px;
  margin-bottom: 1px;
}

.new-amount .btn-large-banking,
.new-amount-mini .btn-large-banking,
.new-amount-small .btn-large-banking {
  width: 100%;
  background-color: #a4c843;
  border-radius: 10px;
  color: white;
  background-size: 32px 32px;
  margin-bottom: 0px;
  height: 62px;
  margin-top: 10%;
}

.new-amount .btn-large-banking:disabled,
.new-amount-mini .btn-large-banking:disabled,
.new-amount-small .btn-large-banking:disabled {
  width: 100%;
  background-color: grey;
  border-radius: 10px;
  color: white;
  background-size: 32px 32px;
  margin-bottom: 0px;
  height: 62px;
  margin-top: 10%;
}

.new-amount .moneyInputField,
.new-amount-mini .moneyInputField,
.new-amount-small .moneyInputField  {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  height: 55px;
}

.new-amount-left,
.new-amount-mini-left,
.new-amount-small-left {
  padding: 25px !important;
}

.new-amount-right,
.new-amount-mini-right,
.new-amount-small-right {
  background-color: #18a9e0;
  padding: 26px !important;
  border-radius: 0px 15px 15px 0px;
}

.new-amount-left .row,
.new-amount-right .row,
.new-amount-mini-left .row,
.new-amount-mini-right .row,
.new-amount-small-left .row,
.new-amount-small-right .row{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.new-amount-left .step-one-col .checking,
.new-amount-mini-left .step-one-col .checking,
.new-amount-small-left .step-one-col .checking{
  padding: 0px;
}

@media screen and (min-width: 640px){
  .new-amount .step-one-col,
  .new-amount-mini .step-one-col,
  .new-amount-small .step-one-col {
    margin-right: 0;
  }
}

/*------------------------------------------------------------- END AMOUNT ---------------*/
/*------------------------------------------------------------- START products ---------------*/

.new-products {
  padding: 20px !important;
  padding: 20px !important;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
  padding-bottom: 5px !important;
}

.new-products .light-blue {
  background-color: white !important;
}

.new-products .light-blue .icon {
  font-size: 4em;
}

.new-products h5 {
  font-size: 18px;
}

.new-products h4{
  font-size: 25px;
}

/*------------------------------------------------------------- END products ---------------*/



.cuba-confirmations label
{
  font-size:1em;
  color:#615a5a;
}

.cuba-confirmations > div
{
  margin-bottom:10px;
}


.radio-buttons > label
{
  color: black;
}

.input-line-height
{
  line-height: 55px;
  font-weight:bold;
}



/*------------------------------------------------------------- START PAG BRASIL ---------------*/

@media screen and (max-width: 640px){
  #pagbrasil .checkout .transfer-summary {
    height: 290px !important;
  }
}

/*------------------------------------------------------------- END PAG BRASIL ---------------*/

#trustpilot .new-products {
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 10px !important;
}

#trustpilot .new-products h4 {
  font-size: 24px;
  margin-top: 0;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

#trustpilot .new-products .row .col.item span{
  display: none;
}

#trustpilot .new-products span.icon {
  display: block !important;
}

#trustpilot .new-amount-mini  .new-amount-mini-left {
  padding: 0px !important;
  margin-bottom: 40px;
}

/*----------------------------------------------------------------------------*/

.checkout-onepage-success .amount-success {
  padding: 0px;
}

.checkout-onepage-success .btn-newsletter,
.checkout-onepage-success .input-newsletter,
.checkout-onepage-success #order-info .btn,
.checkout-onepage-success #order-info .btn-large{
  border-radius: 10px !important;
}
.checkout-onepage-success .input-newsletter {
  border: 1px solid #aaa !important;
}
.checkout-onepage-success .success-info, .checkout-onepage-success .unsuccess-info {
  margin-bottom: 0px;
}

.checkout-onepage-success .checkout-onepage-success-contact .btn-large{
  width: 100% !important;
  background-color: #a4c843 !important;
  border-radius: 10px !important;
  color: white !important;
  background-size: 32px 32px !important;
  margin-bottom: 0px !important;
  height: 62px !important;
}

.checkout-onepage-success .unsuccess-info h5 {
  float: none;
}

.checkout-onepage-success div.new-products {
  padding-top: 0px !important;
  text-align: left;
}

.checkout-onepage-success .new-amount-mini-left {
  padding-top: 0px !important;
}

/* ---------------------------------------------------------------------------------------- modal registration  START */

.reg-holder {
  z-index: 9999 !important;
  width:300px !important;
  margin:0 auto;
  padding:0 !important;
  background:white;
  pointer-events:all;
  border-radius: 10px !important;
}

#login-dp .help-block {
  font-size: 12px
}

.reg-holder .bottom {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  clear: both;
  padding: 20px;
}

.q-login-form .form-group {
  padding-top: 10px;
}

.reg-holder .social-buttons {
  margin: 0px;
  padding-top: 0px;
}

.reg-holder .social-buttons a {
  width: 49%;
}

.registration, .q-login {
  padding:10px;
  background-color: rgb(238, 238, 238);
}

.q-login {
  padding: 10px;
  background-color: rgb(238, 238, 238);
  height: 250px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.registration h3 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;
}

.registration h5 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.clear {clear:both;}

.q-social-login-header
{
  padding: 0px;
  text-align: center;
  background-color: #eee;
}

.q-social-login .social-login #sociallogin_button {
  width: 100%;
}

.q-social-login .social-login #sociallogin_button ul {
  width: 100%;
}

.q-social-login .social-login li {
  width:100%;
}

.q-social-login .social-login li:first-child {
  padding: 0;
}

.q-social-login .social-login .bt-login-social {
  text-indent: -9999px !important;
  border-radius: 0px;
  border:none;
  height:40px;
  width:100%;
}

.q-social-login .social-login .bt-login-social#bt-loginfb {
  background: #2d78bd url("../img/fb-reg.png") no-repeat center center !important;
}

.q-social-login .social-login .bt-login-social#bt-logingo {
  background: #dd4b39 url("../img/go-reg.png") no-repeat center center !important;
}

.q-social-login .social-login .bt-login-social#bt-loginya {
  background: #6f2b90 url("../img/ya-reg.png") no-repeat center center !important;
}

.q-social-login .social-login .bt-login-social span {
  height: 40px !important;
  background: none !important;
}

.reg-holder .registration-form .input-field,
.q-login .input-field
{
  border:0;
  height:42px;
  background:white;
  border-radius: 30px;
  text-align: center;
}

@media screen and (max-width: 500px) {

  .reg-holder .registration-form .input-field,
  .q-login .input-field
  {
    margin-bottom: 10px !important;
  }

}

.text-center
{
  text-align: center;
}

.iziModal {
  box-shadow: 0 0 15px rgb(0 0 0 / 30%);
}

.reg-holder .registration-form .input-field:active, .reg-holder .registration-form .input-field:focus,
.q-login .input-field:active, .q-login .input-field:focus {
  box-shadow: none;
}

.reg-holder .registration-form .submit-holder,
.q-login .submit-holder
{
  margin:0 auto;
  text-align: center;
  padding-bottom: 10px;
}

.reg-holder .registration-form .btn-submit-reg,
.q-login .btn-submit-reg
 {
    background: #8daf33 none;
    box-shadow: none;
    color: #FFF;
    text-align: center;
    letter-spacing: .5px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 30px;
    font-size: 18px;
    height: 39px;
    line-height: 20px;
    width: 100%;
}

#newsletter-validate-detail .btn-submit {
    background: #8daf33 none;
    box-shadow: none;
    color: #FFF;
    text-align: center;
    letter-spacing: .5px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 10px;
    font-size: 18px;
    line-height: 20px;
    width: 100%;
}

.reg-holder .registration-form .btn-submit-reg:hover, .reg-holder .registration-form .btn-submit-reg:focus,
.q-login .btn-submit-reg:hover, .q-login .btn-submit-reg:focus {
    background: #77932b none;
    box-shadow: none;
}

.reg-holder .registration-form .btn-submit-reg:active,
.q-login .btn-submit-reg:active
{
  box-shadow: 1px 2px #9E9E9E;
}

.reg-holder .registration-form .radio-buttons, .contact-form .radio-buttons {
  padding: 0 40px;
  margin-bottom: 15px;
}

.reg-holder .registration-form   .radio-buttons   span, .contact-form   .radio-buttons   span {
  margin-right: 20px;
}

.reg-holder .registration-form   .radio-buttons   label, .contact-form   .radio-buttons   label {
  margin-right: 40px;
}

.reg-holder .registration-form   .picker__date-display, .reg-holder .registration-form   .picker__day--selected, .reg-holder .registration-form   .picker__day--selected:hover, .reg-holder .registration-form   .picker--focused   .picker__day--selected, .contact-form   .picker__date-display, .contact-form   .picker__day--selected, .contact-form   .picker__day--selected:hover, .contact-form   .picker--focused   .picker__day--selected {
  background: #a4c843;
}

.reg-holder .registration-form   .picker__close, .reg-holder .registration-form   .picker__today, .reg-holder .registration-form   .picker__day.picker__day--today, .contact-form   .picker__close, .contact-form   .picker__today, .contact-form   .picker__day.picker__day--today {
  color: #a4c843;
  color: #595959;
}

.reg-holder .registration-form .picker__weekday-display, .contact-form   .picker__weekday-display {
  background: #87a731;
}

.reg-holder .registration-form button.picker__today:focus, .reg-holder .registration-form   button.picker__clear:focus, .reg-holder .registration-form   button.picker__close:focus, .reg-holder .registration-form   .picker__nav--prev:hover, .reg-holder .registration-form   .picker__nav--next:hover, .contact-form   button.picker__today:focus, .contact-form   button.picker__clear:focus, .contact-form   button.picker__close:focus, .contact-form   .picker__nav--prev:hover, .contact-form   .picker__nav--next:hover {
  background: #b7d46a;
}

.reg-holder .registration-form .select-wrapper   input.select-dropdown, .contact-form   .select-wrapper   input.select-dropdown {
  padding: 0 10% !important;
  width: 80%;
}

.reg-holder h5.first-title
{
  margin-top: 0;
  padding-top: 12px;
}



.reg-holder .registration-form .validationerr,
.q-login .validationerr
{
  border: 1px solid #911;
  background-color: #fff6f6;
  padding: 5px 10px;
  border-radius: 5px;
  clear: both;
  float: none;
  color: #911 !important;
  margin: 10px 0;
  font-weight: 400;
  display: none;
}

.reg-holder .pretext
{
  background: white;
  padding: 5px;
  border: 1px dashed #79ae69;
}


.q-login-header .arrow-down {
  transform: rotate(180deg);
  margin-right: -22px !important;
}

.reg-holder .arrow-down {
  width: 44px;
  height: 45px;
  position: relative;
  float: right;
  margin-top: -14px;
  margin-right: -20px;
  margin-left: -50px;
}

.reg-holder .arrow-down:before, .arrow-down:after {
  content: "";
  display: block;
  width: 15px;
  height: 5px;
  background: #9E9E9E;
  position: absolute;
  top: 20px;
  transition: transform .5s;
}

.reg-holder .arrow-down:before {
  right: 18px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transform: rotate(45deg);
}

.reg-holder .arrow-down:after {
  right: 10px;
  transform: rotate(-45deg);
}

.reg-holder .arrow-down.active:before {
  transform: rotate(-45deg);
}

.reg-holder .arrow-down.active:after {
  transform: rotate(45deg);
}

.q-login-forgot-pass
{
  text-align: center;
}

@media screen and (max-width: 320px) and (max-height: 568px) {
  .reg-holder
  {
    position: absolute !important;
  }
  .header.fixed {
    position: absolute;
  }
}

.reg-holder{
  overflow: hidden;
  position:fixed;
  width: 100%;
}

.q-registration-header .arrow-down {
  margin-top: -8px;
  margin-bottom: -10px;
}

.reg-holder{
  top:10px;
}

.reg-holder .icon-close {
  background: #bdbdbd;
  margin-bottom: 10px;
  position: absolute;
  right: 9px;
  top: 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
}

pickup-location .styledSelect, relationship-selector .styledSelect, .relationship-selector, .pickup-location {
  position: static;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  font-weight: normal;
}

.q-login-loading {
  text-align: center;
  padding: 10px;
  background-color: #eff0ee;
  display: none;
}


.history-card {
  max-width: 920px;
  margin: 0 auto 40px;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 40px 65px;
}

.history-card-h2 {
  color: #039ad9;
  font-family: "Helvetica", sans-serif;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2em;
  margin-top: 0;
  margin-bottom: 40px;
}

.card-item {
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
  transition: background-color .15s linear;
  padding-right: 10px;
}

.card-item:nth-of-type(1) {
  border-top: 1px solid #e2e2e2;
}

.card-item-1 {
  width: calc( 100% - 190px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.card-item-1 span {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 5px 10px;
  height: 55px;
  color: #5d6b79;
  font-family: "Helvetica", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2em;
}

.card-item-1 span.card-item-value {
  color: #039ad9;
  font-size: 14px;
  font-weight: 700;
  min-width: 115px;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.d-none {
  display: none !important;
}

.card-item-2 {
  position: relative;
  width: 190px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cst-btn-1 {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 10px 10px 10px;
  height: 50px;
  width: 50px;
  border-radius: 18px;
  background-color: #a4c843;
  color: #ffffff;
  font-size: 13px;
  font-family: "Helvetica", sans-serif;
  font-weight: 650;
  margin-right: 10px;
  align-items: center;
  transition: background-color .15s linear;
}

.cst-btn-1 img {
  transition: -webkit-transform .15s linear;
  transition: transform .15s linear;
  transition: transform .15s linear, -webkit-transform .15s linear;
}

.cst-btn-1:hover {
  background-color: #b7d46a;
}

.cst-btn-1.cst-btn-1-mobile {
  /*
position: absolute;
bottom: 10px;
right: 10px;
margin-right: 0;
*/
  margin: 0 auto;
  clear: both;
}

.color-orange {
  color: #ef7803 !important;
}

.card-circle-arr {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
}

.card-circle-arr img {
  transition: -webkit-transform .15s linear;
  transition: transform .15s linear;
  transition: transform .15s linear, -webkit-transform .15s linear;
}

.card-item.active {
  background-color: #efefef;
}

.card-item.active .card-circle-arr img {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.card-item.active + .card-item-content {
  max-height: 100%;
  opacity: 1;
  padding: 10px 0;
  transition: all 0.15s cubic-bezier(0, 0.99, 0, 0.99);
}

.card-item-content {
  border-bottom: 1px solid #e2e2e2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.15s cubic-bezier(1, 0.01, 1, 0.01);
}

.card-item-content-1 {
  width: 55%;
}

.card-item-content-1-1 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.card-item-content-1-1 span {
  padding: 10px 0 10px 20px !important;
  height: 25px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 115px;
  color: #5d6b79;
  font-family: "Helvetica", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2em;
}

.card-item-content-1-1 a {
  color: #009eda;
}

.card-item-content-1-1 a:focus,
.card-item-content-1-1 a:hover {
  color: #009eda;
}

.card-item-content-2 {
  width: 45%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card-item-content-2 span {
  padding: 5px 10px;
  height: 40px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 115px;
  color: #5d6b79;
  font-family: "Helvetica", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2em;
}


.card-delete-shopping-cart{
  float: left;
  margin-left: 20px !important;
  margin-top: 10% !important;
}

.cst-btn-1.cst-btn-1-span {
  background-color: transparent;
  border: 0;
  color: #5d6b79;
  font-weight: 400;
}

.cst-btn-2 {
  position: relative;
  display: inline-block;
  padding: 17px 40px 16px 40px;
  border-radius: 25px;
  background-color: #009eda;
  color: #ffffff;
  font-size: 16px;
  font-family: "Helvetica", sans-serif;
  font-weight: 700;
  line-height: 1.1em;
  margin: 20px auto 0;
  transition: background-color .15s linear;
}

.cst-btn-2:hover {
  background-color: #0ebdff;
}

.history-tabs-wrap {
  display: none;
}

.history-tabs {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 2px solid #009eda;
  overflow: auto;
}

.history-tabs-link {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 15px 5px 12px;
  border-radius: 3px 3px 0 0;
  background-color: transparent;
  transition: background-color .15s linear,
  color .15s linear;
  color: #5d6b79;
  font-size: 10px;
  font-weight: 400;
  white-space: nowrap;
}

.history-tabs-link.active {
  background-color: #009eda;
  color: #ffffff;
}

.cst-btn-3 {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 13px 9px 17px;
  height: 36px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  color: #5d6b79;
  font-size: 12px;
  font-family: "Helvetica", sans-serif;
  font-weight: 700;
  transition: color .15s linear;
}

.cst-btn-3 img {
  margin-left: 4px;
  transition: -webkit-transform .15s linear;
  transition: transform .15s linear;
  transition: transform .15s linear, -webkit-transform .15s linear;
}

.cst-btn-3:hover {
  color: #039ad9;
}

@media (min-width: 768px) {
  .cst-btn-1.cst-btn-1-mobile {
    display: none;
  }
}

@media (max-width: 767px) {
  .history-card {
    display: none;
    padding: 0 15px 25px;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }

  .history-card.active {
    display: block;
  }

  .card-item:nth-of-type(1) {
    border-top: 0;
  }

  .card-item-1 span.card-item-phone {
    display: none;
  }

  .d-xs-none {
    display: none !important;
  }

  .d-xs-flex {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .card-item-content-1 {
    width: 100%;
  }

  .card-item-content-1-1 span {
    padding: 5px 0 5px 20px !important;
    height: auto;
  }

  .card-item-content-2 {
    width: 100%;
  }

  .card-item-content-2 span {
    width: 100%;
    padding: 10px 10px;
    height: auto;
  }

  .card-item-content-2 span:nth-of-type(1) {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .card-item-content-2 span:nth-of-type(2) {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .history-tabs-wrap {
    display: block;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    background-color: #ffffff;
    padding: 15px 15px 0;
  }

  span.span-btn {
    padding: 0;
    padding-top: 10px;
    padding-left: 10px;
  }
}

.history-field-label {
  color: black !important;
  text-transform: capitalize;

}

.registration .inputs.date-of-birth label,
.registration .col.s4 label{
  margin-left: 3px;
}

#amountForm label{
  display: flex;
  margin: 0px;
}

#receiver-form #accountNumber + label,
#receiver-form #idNumber + label,
#receiver-form #phoneNumber + label,
#receiver-form .col.s12.m6 label{
  margin-left: 8px;
}

.banking-receiver-page .label-iban {
  margin-left: 0px !important;
}

.floating-label{
  color: black !important;
  font-size: 0.9rem !important;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 3px;
  top: -20px;
}

.input-field label {
  top: 1.8rem;
  left: 2.5rem;
}

/*
    this style is for receiverInfo page (for example, for UK)
 */
.floating-input-receiverinfo:focus ~ label, .floating-input-receiverinfo:not(:placeholder-shown) ~ label {
  top:-25px;
  left: 10px;
  font-size:14px !important;
  color:#9e9e9e !important;
}

.floating-div {
  position: relative;
}

.receiver-row {
  margin-top:15px;
}

.reference-raw {
  margin-bottom: 0!important;
}

.reason-raw {
  margin-bottom: 30px;
}

@media screen and (min-width: 700px) {
  .product-choice-height-300 {
    height: 300px !important;
  }
}

/* #region prouct choice */
.icon-ais-new-usd, .icon-ais-recharge-usd {
  color: #009eda !important;
}

.icon-ais-new-cuc, .icon-ais-recharge-cuc {
  color: #009eda !important;
}


.product-choice .row .col {
  padding: 0.75rem 0.75rem !important;
}
/* #endregon product choice */

/* #region product choice cards for small and medium screens on  /banking/transfer/index/country  */
.show-small-screen .product-choice .card,
.show-medium-screen .product-choice .card
{
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.show-small-screen .product-choice .card .col-icon,
.show-medium-screen .product-choice .card .col-icon
{
  width: 30%;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  display: block !important;
}

.show-small-screen .product-choice .card .col-product-header,
.show-medium-screen .product-choice .card .col-product-header
{
  width: 70%;
  text-align: left;
}



.show-small-screen .product-choice .card .col-icon .icon-box-small,
.show-medium-screen .product-choice .card .col-icon .icon-box-small
{
  width: 70px;
  height: 70px;
  margin: 0 auto;
  background: white;
  position: relative;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
  float: unset;
}

.show-small-screen .product-choice .card .col-product-header .product-header,
.show-medium-screen .product-choice .card .col-product-header .product-header
{
  font-size: 1.4em;
  font-weight: bold;

}

.show-small-screen  .product-choice .row-product-header,
.show-medium-screen .product-choice .row-product-header
{
  margin-bottom: 0;
}

.show-small-screen .product-choice .row-product-comment,
.show-medium-screen .product-choice .row-product-comment
{
  margin-bottom: 0;
  color: #919599;
}

.show-small-screen .product-choice .row-product-comment span,
.show-medium-screen .product-choice .row-product-comment span
{
  font-size: 1.1em;
}
/* #endregion product choice cards for small and medium screens */


/* #region product choice cards for large screens on  /banking/transfer/index/country  */
.product-choice .show-large-screen .card .div-comment {
  font-size: smaller;
  color: #444444;
  margin-top: 15px;
}

.product-choice .show-large-screen .card .div-header {
  margin-top: 20px;
  font-size: inherit;
}

.product-choice .show-large-screen .card {
  height:300px;
  width:270px;
  text-align:center;
  padding-top:60px;
  border-radius: 20px;
  -webkit-box-shadow: 3px 3px 15px 7px rgba(0,0,0,0.16);
  -moz-box-shadow: 3px 3px 15px 7px rgba(0,0,0,0.16);
  box-shadow: 3px 3px 15px 7px rgba(0,0,0,0.16);
}
/* #endregion product choice cards for large screens on  /banking/transfer/index/country  */


/* region checkout payment pages such as success, pending and failed */
.checkout-payment.card {
  display: block;
  margin-bottom: 25px;
  margin-top: 10px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.checkout-payment .card-header {
  display: -webkit-box;
  height: 45px;
}

.checkout-payment .failed-payment {
  background-color: #f39c12;
}

.checkout-payment .accepted-payment {
  background-color: #a5c94b;
}
.checkout-payment .pending-payment {
  background-color: #009eda;
}

.checkout-payment .card-header-text {
  text-align: center;
  width: 100%;
  color: white;
  font-size: 18px;
  vertical-align: middle;
  box-sizing: content-box;
}

.checkout-payment .card-header-text span {
  padding-top: 11px;
}

.checkout-payment .card-body p {
  text-align: center;
  font-weight: bold;
}
/* region checkout payment pages such as success, pending and failed */
@media screen and (max-width: 640px) {
  .registration select { display: block; height: 40px !important; }
}

.confirmation-info-img{
  position: absolute;
  cursor: pointer;
}

.confirmation-info-holder .iziModal-header {
  padding-top: 20px !important;
  box-shadow: none !important;
}

.confirmation-info-holder .iziModal-header .iziModal-button-close{
  background-color: black;
}

.account-info .btn-small{
  border: none;
  display: inline !important;
}

/*#region Multi-Factor Authentication*/

.google-authenticator-container > h5{
  text-align: center;
  margin-bottom: 20px;
}

.qr-code-container{
  max-width: 150px;
  margin: 10px auto;
}

.mfaHolder{
  display: none;
}

.mfaActive{
  display: block !important;
}

.otp-code-message{
  padding: 4px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.otp-code-fail{
  background: #ffb2b2;
  display: inline-block;
}

.otp-code-success{
  background: #a4c843;
  display: inline-block;
}

.configure-provider{
  text-align: center;
}

.otp-setup-back-link{
  background: url(../img/icons/slider-left-arrow-blue.png) no-repeat center left 0px;
  background-size: 8px;
  padding-left: 17px !important;
  margin-bottom: 20px;
}

/*#endregion Multi-Factor Authentication*/

.countryConditional{
  display: none;
}

.info-validation{
  background: white;
  padding: 20px;
  border-radius: 20px;
}

.info-validation label{
  font-size: 14px;
  font-weight: bold;
}

.info-validation .btn-large{
  margin:10px;
}

.overlay-message{
  transform: translateY(130%);
  text-align: center;
}

.overlay-message-holder{
  background: #ffffff;
  width: 40%;
  height: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  border-radius: 20px;
}

@media only screen and (max-width: 767px) {
  .overlay-message-holder {
    width: 90%;
    height: 30%;
  }
}

.overlay-holder{
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5) !important;
  z-index: 2;
  cursor: pointer;
}

.overlay-message-holder-pt{
  background: #ffffff;
  max-width: 600px;
  /* height: 30%; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  border-radius: 20px;
  padding-bottom: 37px;
  cursor: default;
}

@media only screen and (max-width: 767px) {
  .overlay-message-holder-pt {
    width: 90%;
    max-height: 90%;
  }
}

.overlay-holder-pt{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5) !important;
  z-index: 2;
  cursor: pointer;
}

.past-transactions-title{
  text-align: center;
  position: relative;
  top: 11px;
  font-size: 19px;
  margin-bottom: 7px;
  font-weight: bold;
  width: 230px;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: default;
}

.past-transactions{
  position: relative;
  top: 16px;
  margin-left: 10px;
  overflow: auto;
  max-height: 500px;
}

.pt-holder{
  position: relative;
  display: inline-block;
  font-size: 17px;
}

.pt-item{
  position: relative;
  padding: 9px;
  cursor: pointer;
}

.pt-item:nth-of-type(odd) {
  background: #e5e5e5;
}

.pt-amount{
  width: 107px;
  top: -12px;
}

.pt-holder .arrow-down.active {
  transform: rotate(180deg);
}

.pt-arrow{
  width: 20px;
  top: -9px;
  margin-right: 8px;
}

.pt-content{
  /* display: none; */
  border-top: 1px solid;
}

.pt-content div{
  margin: 8px;
}

.pt-content span:nth-child(1){
  font-weight: bold;
  width: 115px;
  display: inline-block
}

.open-pt-title{
  border-radius: 10px;
  padding: 6px;
  color: white;
  text-align: center;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: 13px;
}

.pt-close-btn{
  float: right;
  margin-top: 4px;
  margin-right: 9px;
  cursor: pointer;
}

.pt-name{
  font-weight: bold;
}

.pt-account-nr {
  display: inline-block;
}

@media screen and (min-width: 640px) {
  .pt-copy-button{
    display: block !important;
  }
  .pt-copy-button-inner{
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .open-pt-title{
    margin-left: auto;
    margin-right: auto;
    margin-top: -20px;
    margin-bottom: 21px;
    display: table;
    width: 100%;
  }

  .pt-copy-button{
    width: 100%;
    margin-left: 0px !important;
    top: 1px !important;
    margin-bottom: 4px !important;
    float: none !important;
    margin-top: 10px !important;
  }

  .pt-copy-button-inner{
    display: block !important;
  }

  .arrow-down{
    margin-top: 10px
  }
}

.pt-copy-button{
  top: -10px;
  position: relative;
  float: right;
  margin-top: 18px;
  margin-right: 16px;
}

.pt-copy-button-inner{
  display: none;  
}

.pt-copy-button, .open-pt-title {background: #a4c843 none;text-decoration: none;-webkit-box-shadow: none;-moz-box-shadow: none;box-shadow: none;color: #FFF;text-align: center;letter-spacing: .5px;cursor: pointer;-webkit-transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;-ms-transition: 0.3s;transition: 0.3s;-webkit-border-radius: 30px;-moz-border-radius: 30px;border-radius: 30px;border: none;padding: 9px;}
.pt-copy-button:hover, .open-pt-title:hover { background: #8daf33 none; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
  
.update-topic-button{
  text-align: center;
  position: unset;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.messageHolder{
  max-width: 1000px;
  margin: 20px auto;
  text-align: center;
  color: #333;
  font-weight: bold;
  width: 100%;
}

#successMessage {
  border: 1px solid #7AAA65;
  background-color: #ffffff;
  padding: 5px 10px;
  color: #7AAA65;
  font-weight: 400;
  width: 100%;
  display: block;
}

#errorMessage {
  border: 1px solid #911;
  background-color: #fff6f6;
  padding: 5px 10px;
  color: #910 !important;
  font-weight: 400;
  width: 100%;
  display: block;
}

.center-block {
    display: flex;
    align-items: center;
    justify-content: center;
}
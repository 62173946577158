.show-small-screen {
    display: none;
}

.show-medium-screen {
    display: none;
}

.show-large-screen {
    display: none;
}

.center-button {
    width: 100%;
    margin-top: 15px;
}

.wide-button {
    width: 100%;
}

.payment-methods {
    padding: 0 !important;
}

#payment-controls-for-big-screen {
    display: none;
    padding-top: 37px;
}

#description {
    font-size: 13px;
}

.temp-icon {
    width: 85px;
    height: 85px;
    margin-left: auto;
    margin-right: auto;
}

.show-small-screen .product-choice .row-product-comment span,
.show-medium-screen .product-choice .row-product-comment span {
    font-size: 0.9em !important;
}

.show-small-screen .product-choice .row-product-header,
.show-medium-screen .product-choice .row-product-header {
    font-size: 1.4em !important;
}


@media (max-width : 599.99px) {
    #payment-controls-for-small-screen {
        display: block;
        padding-bottom: 20px;
    }
}

@media (min-width : 600px) {
    #payment-controls-for-big-screen {
        display: block;
    }
}

@media (max-width : 500px) {
    .show-small-screen {
        display: block;
    }
}

@media (max-width : 1074px) {
    #description {
        font-size: 13px;
    }
}

@media only screen and (min-width : 1006px) and (max-width : 1073.9px) {
    #description {
        font-size: 12px;
    }
}

@media (max-width : 1005.99px) {
    #description {
        font-size: 11px;
    }
}

@media only screen and (min-width : 514px) and (max-width : 600px) {
    #description {
        font-size: 13px;
    }
}

@media (max-width : 513.99px) {
    #description {
        font-size: 11px;
    }
}

@media only screen and (min-width : 501px) and (max-width : 1006px) {
    .show-medium-screen {
        display: block;
    }
}

@media only screen and (min-width : 1001px) {
    .show-large-screen {
        display: block;
    }
}


/* line 14 : /azp/agent/_work/8/s/src/webpack/src/css/styles.styl */

/* line 1 : /azp/agent/_work/8/s/src/webpack/node_modules/stylus/lib/functions/index.styl */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/styles.styl */

/* line 297 : /azp/agent/_work/8/s/src/webpack/node_modules/stylus/lib/functions/index.styl */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */

/* line 4 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-body{background:#fff !important;height:400px;padding:0;margin:0}
/* line 5 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-body *{padding:0;margin:0}
/* line 6 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice{width:960px;margin:100px auto 0 auto;font-family:Verdana,Geneva,sans-serif !important;color:#51545e;text-align:center}
/* line 7 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice h3{font-size:36px;font-weight:400;line-height:1.35;text-transform:uppercase;color:#51545e}
/* line 8 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice p{margin:20px auto 50px auto;font-size:16px;line-height:1.55;color:#51545e}
/* line 9 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice ul{list-style-type:none}
/* line 10 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice ul li{float:left;display:block;width:480px}
/* line 11 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice ul li a{display:block;width:145px;margin:0 auto;padding:84px 10px 10px 10px;border-bottom:1px solid #51545e;background-color:#fff;background-repeat:no-repeat;background-position:center 10px;color:#51545e;font-size:12px;font-weight:400;text-align:center;text-decoration:none;outline:none}
/* line 12 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice ul li a:hover{border-bottom:1px solid #e4131b;color:#e4131b}
/* line 13 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice ul li .link-chrome{background-image:url("../img/chrome.jpg")}
/* line 14 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice ul li .link-firefox{background-image:url("../img/firefox.jpg")}
/* line 15 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ie-notice .ie-clear{clear:both}
/* line 16 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */
.ltie{display:none}
/* line 16 : /azp/agent/_work/8/s/src/webpack/src/css/miscellaneous.css */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-"]{display:inline-block;height:85px;width:85px;background-repeat:no-repeat !important;background-position:center !important;background-size:cover !important}
/* line 10 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-topup"]{width:49px;background:url("../img/icons/product/min/topup.png")}
/* line 15 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-topup-gray{background:url("../img/icons/product/min/topup_gray.png")}
/* line 19 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-grocery{background:url("../img/icons/product/min/food_basket.png")}
/* line 23 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-grocery-gray{background:url("../img/icons/product/min/food_basket_gray.png")}
/* line 27 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-nauta"]{width:41px;background:url("../img/icons/product/min/nauta.png")}
/* line 31 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-nauta-gray{background:url("../img/icons/product/min/nauta_gray.png")}
/* line 35 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-bank{background:url("../img/icons/product/min/bank.png")}
/* line 39 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-bank-gray{background:url("../img/icons/product/min/bank_gray.png")}
/* line 43 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-card"]{height:59px;background:url("../img/icons/product/min/card.png")}
/* line 48 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-card-gray{background:url("../img/icons/product/min/card_gray.png")}
/* line 52 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-new_card"]{height:53px;background:url("../img/icons/product/min/new_card.png")}
/* line 57 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-new_card-gray{background:url("../img/icons/product/min/new_card_gray.png")}
/* line 61 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-wallet{background:url("../img/icons/product/min/wallet.png")}
/* line 65 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-wallet-gray{background:url("../img/icons/product/min/wallet_gray.png")}
/* line 69 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-s-"]{display:inline-block;height:56px;width:56px;background-repeat:no-repeat !important;background-position:center !important;background-size:cover !important}
/* line 78 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-s-topup"]{width:32px;background:url("../img/icons/product/min-s/topup.png")}
/* line 83 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-topup-gray{background:url("../img/icons/product/min-s/topup_gray.png")}
/* line 87 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-grocery{background:url("../img/icons/product/min-s/food_basket.png")}
/* line 91 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-grocery-gray{background:url("../img/icons/product/min-s/food_basket_gray.png")}
/* line 95 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-s-nauta"]{width:27px;background:url("../img/icons/product/min-s/nauta.png")}
/* line 100 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-nauta-gray{background:url("../img/icons/product/min-s/nauta_gray.png")}
/* line 104 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-bank{background:url("../img/icons/product/min-s/bank.png")}
/* line 108 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-bank-gray{background:url("../img/icons/product/min-s/bank_gray.png")}
/* line 112 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-s-card"]{height:39px;background:url("../img/icons/product/min-s/card.png")}
/* line 117 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-card-gray{background:url("../img/icons/product/min-s/card_gray.png")}
/* line 121 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
[class^="icon-s-new_card"]{height:35px;background:url("../img/icons/product/min-s/new_card.png")}
/* line 127 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-new_card-gray{background:url("../img/icons/product/min-s/new_card_gray.png")}
/* line 131 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-wallet{background:url("../img/icons/product/min-s/wallet.png")}
/* line 135 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-wallet-gray{background:url("../img/icons/product/min-s/wallet_gray.png")}
/* line 139 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-cash{background:url("../img/icons/product/src/money.svg")}
/* line 143 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-cash-gray{background:url("../img/icons/product/src/money_gray.svg")}
/* line 147 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-cash{background:url("../img/icons/product/src/money.svg")}
/* line 151 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.icon-s-cash-gray{background:url("../img/icons/product/src/money_gray.svg")}
/* line 155 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */
.material-icons{font-weight:normal;font-style:normal;font-size:4em;display:inline-block;line-height:1;text-transform:none;letter-spacing:normal;word-wrap:normal;white-space:nowrap;direction:ltr;-webkit-font-smoothing:antialiased;text-rendering:optimizeLegibility;-moz-osx-font-smoothing:grayscale;font-feature-settings:'liga'}
/* line 177 : /azp/agent/_work/8/s/src/webpack/src/css/icons.css */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag{width:32px;height:32px;background:url("../img/flags.png") no-repeat;float:left;margin-right:10px}
/* line 9 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ad{background-position:-32px 0}
/* line 10 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ae{background-position:-64px 0}
/* line 11 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-af{background-position:-96px 0}
/* line 12 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ag{background-position:-128px 0}
/* line 13 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ai{background-position:-160px 0}
/* line 14 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-al{background-position:-192px 0}
/* line 15 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-am{background-position:-224px 0}
/* line 16 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-an{background-position:-256px 0}
/* line 17 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ao{background-position:-288px 0}
/* line 18 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ar{background-position:-320px 0}
/* line 19 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-as{background-position:-352px 0}
/* line 20 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-at{background-position:-384px 0}
/* line 21 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-au{background-position:-416px 0}
/* line 22 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-aw{background-position:-448px 0}
/* line 23 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-az{background-position:0 -32px}
/* line 24 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ba{background-position:-32px -32px}
/* line 25 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bb{background-position:-64px -32px}
/* line 26 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bd{background-position:-96px -32px}
/* line 27 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-be{background-position:-128px -32px}
/* line 28 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bf{background-position:-160px -32px}
/* line 29 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bg{background-position:-192px -32px}
/* line 30 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bh{background-position:-224px -32px}
/* line 31 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bi{background-position:-256px -32px}
/* line 32 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bj{background-position:-288px -32px}
/* line 33 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bm{background-position:-320px -32px}
/* line 34 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bn{background-position:-352px -32px}
/* line 35 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bo{background-position:-384px -32px}
/* line 36 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-br{background-position:-416px -32px}
/* line 37 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bs{background-position:-448px -32px}
/* line 38 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bt{background-position:0 -64px}
/* line 39 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bw{background-position:-32px -64px}
/* line 40 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-by{background-position:-64px -64px}
/* line 41 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-bz{background-position:-96px -64px}
/* line 42 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ca{background-position:-128px -64px}
/* line 43 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cd{background-position:-160px -64px}
/* line 44 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cf{background-position:-192px -64px}
/* line 45 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cg{background-position:-224px -64px}
/* line 46 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ch{background-position:-256px -64px}
/* line 47 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ci{background-position:-288px -64px}
/* line 48 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ck{background-position:-320px -64px}
/* line 49 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cl{background-position:-352px -64px}
/* line 50 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cm{background-position:-384px -64px}
/* line 51 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cn{background-position:-416px -64px}
/* line 52 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-co{background-position:-448px -64px}
/* line 53 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cr{background-position:0 -96px}
/* line 54 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cu{background-position:-32px -96px}
/* line 55 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cv{background-position:-64px -96px}
/* line 56 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cy{background-position:-96px -96px}
/* line 57 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-cz{background-position:-128px -96px}
/* line 58 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-de{background-position:-160px -96px}
/* line 59 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-dj{background-position:-192px -96px}
/* line 60 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-dk{background-position:-224px -96px}
/* line 61 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-dm{background-position:-256px -96px}
/* line 62 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-do{background-position:-288px -96px}
/* line 63 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-dz{background-position:-320px -96px}
/* line 64 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ec{background-position:-352px -96px}
/* line 65 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ee{background-position:-384px -96px}
/* line 66 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-eg{background-position:-416px -96px}
/* line 67 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-eh{background-position:-448px -96px}
/* line 68 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-er{background-position:0 -128px}
/* line 69 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-es{background-position:-32px -128px}
/* line 70 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-et{background-position:-64px -128px}
/* line 71 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-fi{background-position:-96px -128px}
/* line 72 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-fj{background-position:-128px -128px}
/* line 73 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-fm{background-position:-160px -128px}
/* line 74 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-fo{background-position:-192px -128px}
/* line 75 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-fr{background-position:-224px -128px}
/* line 76 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ga{background-position:-256px -128px}
/* line 77 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gb{background-position:-288px -128px}
/* line 78 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gd{background-position:-320px -128px}
/* line 79 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ge{background-position:-352px -128px}
/* line 80 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gg{background-position:-384px -128px}
/* line 81 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gh{background-position:-416px -128px}
/* line 82 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gi{background-position:-448px -128px}
/* line 83 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gl{background-position:0 -160px}
/* line 84 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gm{background-position:-32px -160px}
/* line 85 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gn{background-position:-64px -160px}
/* line 86 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gp{background-position:-96px -160px}
/* line 87 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gq{background-position:-128px -160px}
/* line 88 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gr{background-position:-160px -160px}
/* line 89 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gt{background-position:-192px -160px}
/* line 90 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gu{background-position:-224px -160px}
/* line 91 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gw{background-position:-256px -160px}
/* line 92 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-gy{background-position:-288px -160px}
/* line 93 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-hk{background-position:-320px -160px}
/* line 94 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-hn{background-position:-352px -160px}
/* line 95 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-hr{background-position:-384px -160px}
/* line 96 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ht{background-position:-416px -160px}
/* line 97 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-hu{background-position:-448px -160px}
/* line 98 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-id{background-position:0 -192px}
/* line 99 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ie{background-position:-32px -192px}
/* line 100 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-il{background-position:-64px -192px}
/* line 101 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-im{background-position:-96px -192px}
/* line 102 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-in{background-position:-128px -192px}
/* line 103 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-iq{background-position:-160px -192px}
/* line 104 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ir{background-position:-192px -192px}
/* line 105 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-is{background-position:-224px -192px}
/* line 106 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-it{background-position:-256px -192px}
/* line 107 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-je{background-position:-288px -192px}
/* line 108 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-jm{background-position:-320px -192px}
/* line 109 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-jo{background-position:-352px -192px}
/* line 110 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-jp{background-position:-384px -192px}
/* line 111 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ke{background-position:-416px -192px}
/* line 112 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-kg{background-position:-448px -192px}
/* line 113 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-kh{background-position:0 -224px}
/* line 114 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ki{background-position:-32px -224px}
/* line 115 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-km{background-position:-64px -224px}
/* line 116 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-kn{background-position:-96px -224px}
/* line 117 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-kp{background-position:-128px -224px}
/* line 118 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-kr{background-position:-160px -224px}
/* line 119 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-kw{background-position:-192px -224px}
/* line 120 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ky{background-position:-224px -224px}
/* line 121 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-kz{background-position:-256px -224px}
/* line 122 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-la{background-position:-288px -224px}
/* line 123 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-lb{background-position:-320px -224px}
/* line 124 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-lc{background-position:-352px -224px}
/* line 125 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-li{background-position:-384px -224px}
/* line 126 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-lk{background-position:-416px -224px}
/* line 127 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-lr{background-position:-448px -224px}
/* line 128 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ls{background-position:0 -256px}
/* line 129 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-lt{background-position:-32px -256px}
/* line 130 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-lu{background-position:-64px -256px}
/* line 131 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-lv{background-position:-96px -256px}
/* line 132 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ly{background-position:-128px -256px}
/* line 133 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ma{background-position:-160px -256px}
/* line 134 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mc{background-position:-192px -256px}
/* line 135 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-md{background-position:-224px -256px}
/* line 136 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-me{background-position:-256px -256px}
/* line 137 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mg{background-position:-288px -256px}
/* line 138 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mh{background-position:-320px -256px}
/* line 139 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mk{background-position:-352px -256px}
/* line 140 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ml{background-position:-384px -256px}
/* line 141 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mm{background-position:-416px -256px}
/* line 142 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mn{background-position:-448px -256px}
/* line 143 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mo{background-position:0 -288px}
/* line 144 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mq{background-position:-32px -288px}
/* line 145 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mr{background-position:-64px -288px}
/* line 146 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ms{background-position:-96px -288px}
/* line 147 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mt{background-position:-128px -288px}
/* line 148 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mu{background-position:-160px -288px}
/* line 149 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mv{background-position:-192px -288px}
/* line 150 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mw{background-position:-224px -288px}
/* line 151 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mx{background-position:-256px -288px}
/* line 152 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-my{background-position:-288px -288px}
/* line 153 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-mz{background-position:-320px -288px}
/* line 154 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-na{background-position:-352px -288px}
/* line 155 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-nc{background-position:-384px -288px}
/* line 156 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ne{background-position:-416px -288px}
/* line 157 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ng{background-position:-448px -288px}
/* line 158 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ni{background-position:0 -320px}
/* line 159 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-nl{background-position:-32px -320px}
/* line 160 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-no{background-position:-64px -320px}
/* line 161 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-np{background-position:-96px -320px}
/* line 162 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-nr{background-position:-128px -320px}
/* line 163 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-nz{background-position:-160px -320px}
/* line 164 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-om{background-position:-192px -320px}
/* line 165 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pa{background-position:-224px -320px}
/* line 166 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pe{background-position:-256px -320px}
/* line 167 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pf{background-position:-288px -320px}
/* line 168 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pg{background-position:-320px -320px}
/* line 169 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ph{background-position:-352px -320px}
/* line 170 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pk{background-position:-384px -320px}
/* line 171 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pl{background-position:-416px -320px}
/* line 172 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pr{background-position:-448px -320px}
/* line 173 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ps{background-position:0 -352px}
/* line 174 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pt{background-position:-32px -352px}
/* line 175 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-pw{background-position:-64px -352px}
/* line 176 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-py{background-position:-96px -352px}
/* line 177 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-qa{background-position:-128px -352px}
/* line 178 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-re{background-position:-160px -352px}
/* line 179 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ro{background-position:-192px -352px}
/* line 180 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-rs{background-position:-224px -352px}
/* line 181 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ru{background-position:-256px -352px}
/* line 182 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-rw{background-position:-288px -352px}
/* line 183 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sa{background-position:-320px -352px}
/* line 184 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sb{background-position:-352px -352px}
/* line 185 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sc{background-position:-384px -352px}
/* line 186 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sd{background-position:-416px -352px}
/* line 187 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-se{background-position:-448px -352px}
/* line 188 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sg{background-position:0 -384px}
/* line 189 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-si{background-position:-32px -384px}
/* line 190 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sk{background-position:-64px -384px}
/* line 191 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sl{background-position:-96px -384px}
/* line 192 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sm{background-position:-128px -384px}
/* line 193 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sn{background-position:-160px -384px}
/* line 194 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-so{background-position:-192px -384px}
/* line 195 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sr{background-position:-224px -384px}
/* line 196 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-st{background-position:-256px -384px}
/* line 197 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sv{background-position:-288px -384px}
/* line 198 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sy{background-position:-320px -384px}
/* line 199 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-sz{background-position:-352px -384px}
/* line 200 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tc{background-position:-384px -384px}
/* line 201 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-td{background-position:-416px -384px}
/* line 202 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tg{background-position:-448px -384px}
/* line 203 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-th{background-position:0 -416px}
/* line 204 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tj{background-position:-32px -416px}
/* line 205 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tl{background-position:-64px -416px}
/* line 206 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tm{background-position:-96px -416px}
/* line 207 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tn{background-position:-128px -416px}
/* line 208 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-to{background-position:-160px -416px}
/* line 209 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tr{background-position:-192px -416px}
/* line 210 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tt{background-position:-224px -416px}
/* line 211 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tv{background-position:-256px -416px}
/* line 212 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tw{background-position:-288px -416px}
/* line 213 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-tz{background-position:-320px -416px}
/* line 214 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ua{background-position:-352px -416px}
/* line 215 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ug{background-position:-384px -416px}
/* line 216 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-us{background-position:-416px -416px}
/* line 217 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-uy{background-position:-448px -416px}
/* line 218 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-uz{background-position:0 -448px}
/* line 219 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-va{background-position:-32px -448px}
/* line 220 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-vc{background-position:-64px -448px}
/* line 221 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ve{background-position:-96px -448px}
/* line 222 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-vg{background-position:-128px -448px}
/* line 223 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-vi{background-position:-160px -448px}
/* line 224 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-vn{background-position:-192px -448px}
/* line 225 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-vu{background-position:-224px -448px}
/* line 226 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ws{background-position:-256px -448px}
/* line 227 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-ye{background-position:-288px -448px}
/* line 228 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-za{background-position:-320px -448px}
/* line 229 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-zm{background-position:-352px -448px}
/* line 230 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */
.flag.flag-zw{background-position:-384px -448px}
/* line 230 : /azp/agent/_work/8/s/src/webpack/src/css/flags.css */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */

/* line 2 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.menu-li-spacy-text li{padding:-1 15px !important}
/* line 4 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.menu-li-spacy-text .continue-link{font-size:11.5px;text-transform:capitalize;font-weight:normal}
/* line 7 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */

/* line 8 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.menu-li-middle-spacy-text li{padding:-1 10px !important}
/* line 10 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.menu-li-middle-spacy-text .continue-link{font-size:11.5px;text-transform:capitalize;font-weight:normal}
/* line 13 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */

/* line 14 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.menu-li-compact-text li{padding:-1 7px !important}
/* line 16 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.menu-li-compact-text .continue-link{font-size:10px;text-transform:capitalize;font-weight:normal}@media only screen and (max-device-width:321px){
/* line 21 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.mobile-spam{width:100%;float:inherit}}
/* line 28 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
#senderAmount::-ms-clear{display:none}
/* line 29 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.currencyRightField{display:block;border-left:2px solid #ccc;font-weight:bold;width:90px;height:100%;float:right;line-height:55px;padding-right:9px}
/* line 30 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.selectCurrency{display:-webkit-inline-box !important;-webkit-appearance:none;-moz-appearance:none;border:none;outline:none;width:70%;overflow:hidden;background:#fff url("../img/icons/multicurrency-select-element.png") no-repeat 30px 60%;padding:0;display:block}
/* line 31 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.selectCurrency:focus{outline:none;outline-width:0}
/* line 45 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.displayFlag,.displayCurrency,.arrowImg{margin:3px}
/* line 49 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.displayFlag{width:30px;float:left;margin-top:16px}
/* line 55 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.td-country{float:left}
/* line 59 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.td-flag{max-width:35px;vertical-align:middle}
/* line 64 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.displayCurrency{float:left}
/* line 68 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.arrowImg{width:15px;float:right;margin-top:20px}
/* line 74 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.currencyRightField{width:115px !important}
/* line 78 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-tr{border-bottom:1px dotted #d9d9d9}
/* line 82 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-table tbody tr{padding:0 !important;text-align:center}
/* line 87 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-table tbody td{padding:0 !important;text-align:center}
/* line 92 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.search_input_dropdown{background-position:14px 12px;background-repeat:no-repeat;font-size:16px;padding:14px 20px 12px 45px;border:none;border-bottom:1px solid #ddd;border-bottom:2px solid #e5e5e5 !important;width:90% !important;margin-bottom:0 !important}
/* line 104 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.search_input_dropdown:focus{outline:none}
/* line 108 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown{position:relative;display:inline-block}
/* line 113 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-content{display:none;position:absolute;min-width:230px;overflow:auto;border:1px solid #ddd;z-index:1;opacity:1 !important;overflow-x:hidden;border-radius:10px}
/* line 125 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-content a{color:#000;padding:12px 16px;text-decoration:none;display:block}
/* line 133 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-content::-webkit-scrollbar{width:12px}
/* line 138 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-content::-webkit-scrollbar-track{box-shadow:inset 0 0 5px #808080;border-radius:10px}
/* line 144 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-content::-webkit-scrollbar-thumb{background:#959595;border-radius:10px}
/* line 150 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-content::-webkit-scrollbar-thumb:hover{background:#a4a4a4}
/* line 154 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown-table tr:hover{background-color:#bee1fe}
/* line 158 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.dropdown a:hover{background-color:#ddd}
/* line 165 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
[v-cloak] > *{display:none !important}
/* line 169 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
[v-cloak]::before{display:none !important}
/* line 173 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
[v-cloak]{display:none !important}
/* line 179 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */

/* line 181 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.banking-receiver-page .iban-receiver-info-label{margin-top:-10px}
/* line 185 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.banking-receiver-page .label-iban{margin-left:30px}
/* line 189 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */

/* line 190 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.customer-info-page .info-msg{border:1px solid #000;background-color:#fff;color:#000 !important}@media only screen and (max-device-width:321px){
/* line 197 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.customer-info-page input[type="submit"]{width:100% !important}
/* line 201 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.customer-info-page .account-title{background-image:none;padding-left:0}}@media only screen and (max-device-width:321px){
/* line 208 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.customer-info-page .mobile-spam{width:100%;float:inherit}}
/* line 215 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
@media only screen and (min-width:435px){
/* line 217 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .method_div{width:100%}
/* line 218 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .img_one_method{display:inline-block}
/* line 219 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .description_one_method{width:74%;display:inline-block;vertical-align:top}}@media only screen and (max-width:430px){
/* line 222 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .img_one_method{width:24% !important;display:inline-block !important}
/* line 223 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .description_one_method{width:74% !important;display:inline-block !important;vertical-align:top !important}}
/* line 226 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .coupon{background:#009eda none;text-decoration:none;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;color:#fff;text-align:center;letter-spacing:.5px;cursor:pointer;-webkit-transition:.3s;-moz-transition:.3s;-o-transition:.3s;-ms-transition:.3s;transition:.3s;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;height:40px;line-height:40px;padding:0 1rem}
/* line 227 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .coupon:hover{background:#4b8fe1 none;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none}
/* line 228 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .coupon:focus{background:#4b8fe1 none;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none}
/* line 229 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .coupon:active{background:#4b8fe1 none;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none}
/* line 230 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .input-text{height:40px;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;padding:0 8%;border:none;outline:none}
/* line 231 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .discount{margin:0 0 40px 63px}
/* line 232 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.nop-checkout-page .coupon-already-applied{background-color:#d3d3d3}
/* line 236 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.receiver-row-ripple{font-size:1.6em !important;font-weight:bold;border-bottom:1px dashed #cfc4c4;color:#7f7b7b;margin-bottom:30px}
/* line 245 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */

/* line 247 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.sales-order-history .card-item{height:fit-content;padding-right:0;min-height:70px;align-items:center;display:flex}
/* line 255 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.sales-order-history .card-item-amount-div{width:20%;font-size:19px;text-align:center;color:#039ad9;font-weight:600}
/* line 263 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.sales-order-history .card-item-receiver-name-div{width:50%;padding-top:10px;padding-bottom:10px}
/* line 269 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.sales-order-history .card-item-receiver-name-div div{width:100%;text-align:left;font-size:15px;font-weight:550;padding-left:15px}
/* line 277 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.sales-order-history .card-item-status-text-div{width:100%;font-size:13px;padding-left:15px;font-weight:normal !important}
/* line 284 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.sales-order-history .card-item-resend-btn{width:30%;padding-left:44px;display:flex;justify-content:flex-end}
/* line 293 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */
.random{font-size:100%}
/* line 295 : /azp/agent/_work/8/s/src/webpack/src/css/extracted-css-from-php-files.styl */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 1 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.validation-advice,.notice-msg,#error-message,.error-msg,.success-msg,.error-message{display:block}
/* line 4 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 5 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.validation-advice ul{margin:0}
/* line 8 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.validation-advice.field-validation-valid{display:none}
/* line 12 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.continue-button-on-banking-amount{margin-left:0;margin-right:0;margin-top:20px}
/* line 18 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 19 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.amount-box-on-landing-page .row .col.l9{width:100% !important;margin-left:0}
/* line 23 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.amount-box-on-landing-page .cuba-cuc-btn{width:100%;border-bottom-left-radius:10px}
/* line 27 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.amount-box-on-landing-page .cuba-usd-btn{width:100%;background-color:#039be5;border-bottom-right-radius:10px}
/* line 31 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.amount-box-on-landing-page .cuba-usd-btn:hover{background-color:#2bbbad}
/* line 34 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.amount-box-on-landing-page .btn.disabled{background-color:#dfdfdf;color:#9f9f9f}
/* line 38 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.amount-box-on-landing-page .continue-button-on-banking-amount{margin-top:0}
/* line 44 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
i[data-formatamounttolocale]{font-style:normal}
/* line 50 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
[v-cloak] > *{display:none !important}
/* line 54 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
[v-cloak]::before{display:none !important}
/* line 58 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
[v-cloak]{display:none !important}
/* line 66 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 67 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.nop-checkout-page .payment-method-no-checkbox{min-width:12px;display:inline-block;padding-right:30px}
/* line 72 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.nop-checkout-page #payment-controls-for-big-screen .img_not_one_method{margin-top:10px}@media screen and (max-width:640px){
/* line 79 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.hidden-logo{display:block}}
/* line 86 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
#hooyu-frame{height:1800px}
/* line 93 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 94 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.checkout-onepage-index .mobile-cart,.main-topup-page .mobile-cart,.banking-amount-page .mobile-cart,.banking-receiver-page .mobile-cart,.checkout-success .mobile-cart,.checkout-onepage-index .main-menu .cart,.main-topup-page .main-menu .cart,.banking-amount-page .main-menu .cart,.banking-receiver-page .main-menu .cart,.checkout-success .main-menu .cart{display:none}
/* line 100 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 101 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.receiver-row .phonePrefix{text-align:right;width:35px;float:right}
/* line 110 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.footer-powered-by{text-align:center}
/* line 116 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 117 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.greyedout .icon,.greyedout span{color:#808080 !important}
/* line 121 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 122 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.blog-list .blog .content{padding-right:0;border-right:0}
/* line 125 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.blog-list .col-right.sidebar{display:none}
/* line 127 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.blog-list ul#pagination{padding-left:20px}
/* line 129 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.blog-list #pagination li{list-style-type:disc}
/* line 132 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */

/* line 133 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.html-contact-page .materialize-textarea{overflow-y:auto}
/* line 136 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
div.row span.currencyPlaceholder{display:inline}
/* line 139 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
input.no-spin-number-input::-webkit-outer-spin-button,input.no-spin-number-input::-webkit-inner-spin-button{-webkit-appearance:none !important;margin:0 !important}
/* line 144 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
input.no-spin-number-input[type=number]{-moz-appearance:textfield !important}
/* line 147 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
input.no-spin-number-input:focus{border:none !important;box-shadow:none !important}
/* line 151 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.newsletter-anchor{color:#fff;text-decoration:underline}
/* line 154 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.blue-notification-bar{font-size:13px;margin-top:20px;background-color:#fff;height:fit-content;padding:10px;text-align:center;display:flex;justify-content:center;align-items:center}
/* line 165 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.pointer-events-none{pointer-events:none}
/* line 168 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.cms-page-view{div:#adadad vice-required-entry-email;width:100%}
/* line 172 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.over-flow-y-auto{overflow-y:auto !important}
/* line 175 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.no-border{border:0 !important;box-shadow:none !important}@media only screen and (max-width:600px){
/* line 180 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.moneyLeftField{width:9em}}@media only screen and (max-width:350px){
/* line 184 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.moneyLeftField{width:7em}}@media only screen and (min-width:600px){
/* line 188 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.moneyLeftField{width:11em}}
/* line 191 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.row.checking a.newsletter-anchor{color:#911}
/* line 195 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.nationalities .tt-input,.countries .tt-input{background:url("../img/icons/arrow-down-green-big.png") right 5px center no-repeat #fff !important;cursor:pointer}
/* line 200 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.p_method_label{font-weight:normal;color:#000;text-transform:capitalize;line-height:40px !important}
/* line 206 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.p_method_label img{height:38px;vertical-align:top;padding-bottom:7px}
/* line 212 : /azp/agent/_work/8/s/src/webpack/src/css/additional-styles.styl */
.dummy{color:inherit}